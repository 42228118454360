
import { defineComponent, ref, reactive } from "vue";
import { uploadBlueInvoiceCategory } from "@/API/closing";
import { message } from "ant-design-vue";
import downloadFile from "@/utils/rv/downloadFile";
import isExcel from "@/utils/closing/isExcel";
export default defineComponent({
  emits: ["close-drawer", "handle-upload-success"],
  props: {
    showDrawer: {
      default: false,
      type: Boolean,
    },
    make: {
      default: '',
      type: String
    },
    makeId: {
      default: -1,
      type: Number,
    }
  },
  setup(props, { emit }) {
    // 是否显示上传框
    const showUpload = ref(true);
    const percent = ref(0);
    const uploadFlag = ref(true);
    const file = ref();
    const handleClose = () => {
      emit("close-drawer");
    };
    const uploadConfig = {
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent.lengthComputable) {
          const complete =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          percent.value = complete;
        }
      },
    };
    const uploadRequest = (source: any) => {
      showUpload.value = false;
      if (source.file) {
        uploadFlag.value = false;
      } else {
        uploadFlag.value = true;
      }
      file.value = source.file;
    };
    const handleUpload = async () => {
      const fileIsExcel = await isExcel(file.value);

      const resetComponentStatus = () => {
        showUpload.value = true;
        uploadFlag.value = true;
        percent.value = 0;
      };

      if (!fileIsExcel) {
        message.error("File type error");
        resetComponentStatus();
        return;
      }

      const data = new FormData();
      data.append("file", file.value);

      try {
        const res = await uploadBlueInvoiceCategory({mcId: props.makeId}, data, uploadConfig);
        // 上传之后重置上传组件状态
        showUpload.value = true;
        uploadFlag.value = true;
        percent.value = 0;
        if (res.code === "0") {
          message.success("submit success");
          emit("handle-upload-success");
        } else {
          message.error("submit failed");
        }
      } catch (e) {
        // 上传出错重置上传组件状态
        resetComponentStatus();
      }
    };
    // 下载模板
    const downLoadTemp = () => {
      downloadFile({
        url: '/pcapi/closing/blueInvoice/category/template',
        method: 'get',
        fileName: 'oasis_blue_invoice_template.xlsx'
      })
    };
    // 上传列表
    const columnFileList = reactive([
      {
        dataIndex: "fileName",
        key: "fileName",
        slots: { title: "customTitle", customRender: "fileName" },
      },
      {
        dataIndex: "uploadTime",
        key: "uploadTime",
        slots: { title: "uploadTitle", customRender: "uploadTime" },
      },
    ]);
    const dataFileList = reactive([
      {
        fileName: "Flie xxx1",
        uploadTime: "2021-11-11",
      },
      {
        fileName: "Flie xxx2",
        uploadTime: "2021-11-11",
      },
    ]);
    return {
      handleClose,
      uploadRequest,
      handleUpload,
      showUpload,
      uploadFlag,
      percent,
      downLoadTemp,
      columnFileList,
      dataFileList,
    };
  },
});
