
import { defineComponent, ref } from 'vue';
import { uploadCalculationDetail } from '@/API/closing';
import { message } from 'ant-design-vue';
import isExcel from '@/utils/closing/isExcel';
import blobDownload from '@/utils/blobDownload';

export default defineComponent({
  emits: ['close-drawer', 'handle-upload-success'],
  props: {
    showDrawer: {
      default: false,
      type: Boolean
    },
    makeId: {
      default: -1,
      type: Number
    }
  },
  setup(props, { emit }) {
    // 是否显示上传框
    const showUpload = ref(true);
    const percent = ref(0);
    const uploadFlag = ref(true);
    const file = ref();

    const handleClose = () => {
      emit('close-drawer');
    };
    const uploadConfig = {
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent.lengthComputable) {
          const complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          percent.value = complete;
        }
      }
    };
    const uploadRequest = (source: any) => {
      showUpload.value = false;
      if (source.file) {
        uploadFlag.value = false;
      } else {
        uploadFlag.value = true;
      }
      file.value = source.file;
    };
    const handleUpload = async () => {
      if (!(await isExcel(file.value))) {
        // 出错之后重置上传组件状态
        showUpload.value = true;
        uploadFlag.value = true;
        percent.value = 0;
        message.error('file type error');
        return;
      }
      const uploadFormData = new FormData();
      uploadFormData.append('file', file.value);
      const mcId = props.makeId;
      try {
        const res = await uploadCalculationDetail(mcId, uploadFormData, uploadConfig);
        // 上传之后重置上传组件状态
        showUpload.value = true;
        uploadFlag.value = true;
        percent.value = 0;
        if (res.code === '0') {
          message.success('submit success');
          emit('handle-upload-success');
        } else {
          message.error('submit failed');
        }
      } catch (e) {
        // 上传出错重置上传组件状态
        showUpload.value = true;
        uploadFlag.value = true;
        percent.value = 0;
      }
    };

    return {
      handleClose,
      uploadRequest,
      handleUpload,
      showUpload,
      uploadFlag,
      percent
    };
  }
});
