
import { defineComponent, nextTick, ref, watch } from "vue";
import Basic from "@/views/Closing/Tab/BasicSetting.vue";
import DataPreparation from "@/views/Closing/Tab/DataPreparation.vue";
import GenerateClosing from "@/views/Closing/Tab/GenerateClosing.vue";
import FinalView from "@/views/Closing/Tab/FinalView.vue";
import Pbp from "@/views/Closing/Tab/Pbp/Pbp.vue";
enum TabType {
  BasicSetting,
  DataPreparation,
  GenerateClosing,
  FinalView
}
export default defineComponent({
  components: {
    Basic,
    DataPreparation,
    GenerateClosing,
    FinalView,
    Pbp
  },
  setup() {
    const activeKey = ref<string>("BasicSetting");
    // 是否跳转到Blue Invoice
    const activeName = ref<string>("");
    const cbVersion = ref("");
    const basicSetting = ref();
    const dataPreparation = ref();
    const finalView = ref();
    const make = ref<string>("");
    const makeYear = ref<string>("");
    const makeMonth = ref<string>("");
    const generateClosing = ref();
    const breadcrumbVersion = ref("");
    // 接收传入的ID
    const makeId = ref<number>();
    const breadcrumbName = ref("Homepage");
    const handleChangeTab = () => {
      activeKey.value === "BasicSetting" && basicSetting.value.initData();
      if (activeKey.value === "BasicSetting") {
        breadcrumbName.value = "Homepage";
      }

      nextTick(() => {
        dataPreparation.value?.timer.value && clearInterval(dataPreparation.value.timer.value);
        activeKey.value === "FinalView" && finalView.value.initState();
        activeKey.value === "DataPreparation" &&
          dataPreparation.value.initNew();
      });
    };
    watch(
      () => activeKey.value,
      () => {
        activeKey.value !== "DataPreparation" &&
          dataPreparation.value?.timer &&
          clearInterval(dataPreparation.value.timer);
      }
    );
    // 是否显示pbp表格
    const showPbp = ref<boolean>(false);
    const handleShowPbp = () => {
      
      showPbp.value = true;
      console.log(showPbp.value )
    };
    const showPbpFalse=()=>{
      showPbp.value = false;
    }
    const tabChangEvent = (
      tab: string,
      closingMake = "",
      version = "",
      id = -1,
      month = "",
      year = "",
      jumpName = ""
    ) => {
      activeKey.value = tab;
      make.value = closingMake;
      // 重置 Data Preparation 显示页面
      showPbp.value = false;
      makeId.value = id;
      makeYear.value = year;
      makeMonth.value = month;
      if (tab === "BasicSetting") {
        breadcrumbName.value = "Homepage";
      } else {
        breadcrumbName.value = `${closingMake} > ${version}`;
      }
      cbVersion.value = version;
      breadcrumbVersion.value = version;
      activeName.value = jumpName;
      nextTick(() => {
        console.log(dataPreparation)
        tab === "DataPreparation" && dataPreparation.value.initNew();
        tab === "FinalView" && finalView.value.initState();
        tab === "GenerateClosing" &&
          jumpName !== "blueInvoice" &&
          generateClosing.value.programBase.reset();
      });
    };
    const changeMakeId = (id = -1) => {
      makeId.value = id;
    };
    const isfinal = ref(true);
  const  changeFinal=(value: any)=>{
isfinal.value=value;
  }
    return {
      changeFinal,
      activeKey,
      activeName,
      tabChangEvent,
      changeMakeId,
      make,
      showPbp,
      handleShowPbp,
      breadcrumbName,
      makeId,
      basicSetting,
      dataPreparation,
      handleChangeTab,
      makeMonth,
      makeYear,
      breadcrumbVersion,
      generateClosing,
      finalView,
      cbVersion,
      TabType,
      showPbpFalse,
      isfinal
    };
  }
});
