
import {
  getNstGroupLeftList,
  searchList,
  checkModel,
  saveModel
} from "@/API/closing";
import { defineComponent, ref } from "vue";
const columns = [
  {
    title: "Material Sales",
    dataIndex: "materialSales",
    key: "materialSales"
  },
  {
    title: "Vehicle Model",
    dataIndex: "vehicleModel",
    key: "vehicleModel",
    width: 270
  },
  {
    title: "Cash Discount",
    dataIndex: "cashDiscount",
    key: "cashDiscount",
    align: "right"
  },
  {
    title: "WS LLP",
    dataIndex: "wsLlp",
    key: "wsLlp",
    align: "right"
  },
  {
    title: "WS Volume",
    dataIndex: "wsVolume",
    key: "wsVolume",
    align: "right"
  },
  {
    title: "NST Group Mapping",
    dataIndex: "NSTGroupMapping",
    key: "NSTGroupMapping",
    width: 200,
    slots: { customRender: "NSTGroupMapping" }
  }
];
export default defineComponent({
  emits: ["update:visible", "confirm", "init-data", "refresh-check"],
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      default: "NST Group Maintain"
    },
    makeId: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const rightVisible = ref(false);

    const searchMarterial = ref(null);

    const searchVehicle = ref(null);
    // 左侧表格

    const dataSource: any = ref([]);
    // 搜索结果表格
    const columnSearch = [
      {
        title: "Material No.",
        dataIndex: "materialNo",
        key: "materialNo"
      },
      {
        title: "Material Name",
        dataIndex: "materialName",
        key: "materialName"
      },
      {
        title: "NST Group",
        dataIndex: "nspGroup",
        key: "nspGroup"
      },
      {
        title: "NST Group Name",
        dataIndex: "nspGroupName",
        key: "nspGroupName"
      },
      {
        title: "Impact Name",
        dataIndex: "imapctName",
        key: "imapctName"
      }
    ];
    const dataSearch: any = ref([]);
    const ntsGroupSelected = ref<string[]>([]);
    const changeNtsGroupSelected = (selectedRowKeys: string[]) => {
      ntsGroupSelected.value = selectedRowKeys;
    };

    //防抖函数
    const debounce = (fn: Function, delay: number) => {
      let timer = 0;
      return function() {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          fn();
        }, delay);
      };
    };
    // 校验信息发起接口请求
    const checkKeyDebounce = (function() {
      const store = new Map();
      return function(index: number) {
        let checkFunc = store.get(index);
        if (!checkFunc) {
          checkFunc = debounce(async function() {
            const data = await checkModel({
              models: [dataSource.value[index].ntsGroup]
            });
            dataSource.value[index].ntsGroupMappingError = !data[0].pass;
          }, 500);
          store.set(index, checkFunc);
        }
        checkFunc();
      };
    })();
    const checkKey = (index: number) => checkKeyDebounce(index);
    const ntsGroupSearchRowIndex = ref<number>();
    const showRightSearch = (index: number) => {
      ntsGroupSearchRowIndex.value = index;
      rightVisible.value = true;
    };
    const onSearch = (value: string) => {
      searchList({ materialNo: value })
        .then(res => {
          ntsGroupSelected.value = [];
          dataSearch.value = Array.from(res);
        })
        .catch(err => {
          console.log(err, "查询报错");
        });
    };
    const onSearch1 = (value: string) => {
      searchList({ materialName: value })
        .then(res => {
          ntsGroupSelected.value = [];
          dataSearch.value = Array.from(res);
        })
        .catch(err => {
          console.log(err, "查询报错");
        });
    };
    const initLeftList = () => {
      getNstGroupLeftList({ mcId: props.makeId })
        .then(res => {
          dataSource.value = Array.from(res).map((item: any) => {
            // console.log(item, "单项信息");
            item.ntsGroup = "";
            item.ntsGroupMappingError = false;
            item.wsLlp = parseInt(item.wsLlp);
            item.cashDiscount = item.cashDiscount
              ? parseInt(item.cashDiscount)
              : "";
            return item;
          });
        })
        .catch(err => {
          console.log(err, "NST报错");
        });
    };
    // 操作按钮
    const save = async () => {
      const models = Array.from(
        new Set(dataSource.value.map((item: any) => item.ntsGroup))
      );
      const data = await checkModel({ models });
      const ntsGruopMappingResult = new Map();
      for (const item of data) {
        ntsGruopMappingResult.set(item.model, item.pass);
      }
      let ntsGruopMappingError = true;
      for (const item of dataSource.value) {
        const ntsGroupMappingError = ntsGruopMappingResult.get(item.ntsGroup);
        item.ntsGroupMappingError = !ntsGroupMappingError;
        if (!ntsGroupMappingError) {
          ntsGruopMappingError = false;
        }
      }
      if (ntsGruopMappingError) {
        const data = dataSource.value.map((item: any) => {
          return {
            id: item.id,
            nstGroupCode: item.ntsGroup
          };
        });
        await saveModel(data);
        initLeftList();
        // emit("init-data");
        emit("refresh-check", "nst"); //触发新的nst校验
        emit("update:visible", false); //保存成功关闭弹窗
      }
    };
    const confirm = () => {
      if (ntsGroupSearchRowIndex.value !== undefined) {
        dataSource.value[ntsGroupSearchRowIndex.value].ntsGroup =
          dataSearch.value[ntsGroupSelected.value[0]].nspGroup;
        dataSource.value[
          ntsGroupSearchRowIndex.value
        ].ntsGroupMappingError = false;
        rightVisible.value = false;
      }
    };

    initLeftList();
    return {
      showRightSearch,
      rightVisible,
      searchMarterial,
      searchVehicle,
      columnSearch,
      dataSearch,
      onSearch,
      onSearch1,
      save,
      confirm,
      dataSource,
      checkKey,
      columns,
      ntsGroupSelected,
      changeNtsGroupSelected
    };
  }
});
