
import { defineComponent, ref, reactive, computed, provide } from 'vue';
import { getOverview, dealerStockAccess, getIsYearlyAvailable, setCurrentProgramByYearly } from '@/API/closing';
import BlueInvoice from '@/views/Closing/Tab/Components/BlueInvoice.vue';
import programBase from '@/views/Closing/Tab/Components/ProgramBase.vue';
import SpecialAdjustment from '@/views/Closing/Tab/Components/SpecialAdjustment.vue';
import Release from '@/views/Closing/Tab/Components/Release.vue';
import Interest from '@/views/Closing/Tab/Components/Interest.vue';
import DealerStock from '@/views/Closing/Tab/Components/DealerStock.vue';
import DealerStockYearly from '@/views/Closing/Tab/Components/DealerStockYearly.vue';
import { message } from 'ant-design-vue';
type TableDataType = {
  name: string;
  value: string;
};
export default defineComponent({
  components: {
    BlueInvoice,
    programBase,
    Interest,
    SpecialAdjustment,
    Release,
    DealerStock,
    DealerStockYearly
  },
  props: {
    make: {
      type: String,
      default: ''
    },
    makeId: {
      type: Number,
      required: true,
      default: 1
    },
    makeYear: {
      type: String,
      default: ''
    },
    makeMonth: {
      type: String,
      default: ''
    },
    activeName: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const blueInvoice = ref();
    const programBase = ref();
    const searchMarterial = ref(null);
    const searchVehicle = ref(null);
    const enumTitle = {
      '01': 'MTD Jan',
      '02': 'MTD Feb',
      '03': 'MTD Mar',
      '04': 'MTD Apr',
      '05': 'MTD May',
      '06': 'MTD Jun',
      '07': 'MTD Jul',
      '08': 'MTD Aug',
      '09': 'MTD Sep',
      '10': 'MTD Oct',
      '11': 'MTD Nov',
      '12': 'MTD Dec'
    };
    // 搜索结果表格
    const columnSearch = reactive([
      {
        title: 'MTD Jun',
        dataIndex: 'MaterialSales',
        key: 'MaterialSales',
        align: 'right'
      },
      {
        title: 'Vehicle Model',
        dataIndex: 'VehicleModel',
        key: 'VehicleModel',
        align: 'right'
      }
    ]);
    const oneTable = reactive<TableDataType[]>([
      { name: '', value: '' },
      { name: '', value: '' }
    ]);
    const oneTitle = enumTitle[props.makeMonth];
    const twoTable = reactive<TableDataType[]>([
      { name: '', value: '' },
      { name: '', value: '' }
    ]);
    const dataSearch = reactive([
      {
        VehicleModel: 'E260 style',
        MaterialSales: '21308010-CN1'
      },
      {
        VehicleModel: 'E260 style',
        MaterialSales: '21308010-CN2'
      }
    ]);
    const columnSearch2 = reactive([
      {
        dataIndex: 'cr',
        key: 'cr',
        width: '120px',
        align: 'right',
        slots: { title: 'crTitle', customRender: 'cr' }
      },
      {
        dataIndex: 'fleetUc',
        key: 'fleetUc',
        width: '120px',
        align: 'right',
        slots: { title: 'fleetUcTitle', customRender: 'fleetUc' }
      },
      {
        dataIndex: 'interest',
        key: 'interest',
        width: '150px',
        align: 'right',
        slots: { title: 'interestTitle', customRender: 'interest' }
      },
      {
        dataIndex: 'blueInvoice',
        key: 'blueInvoice',
        width: '120px',
        align: 'right',
        slots: { title: 'customTitle', customRender: 'blueInvoice' }
      },
      {
        dataIndex: 'dlrStockAdj',
        key: 'dlrStockAdj',
        align: 'right',
        width: '110px',
        slots: { title: 'dlrStockAdjTitle', customRender: 'dlrStockAdj' }
      },
      {
        dataIndex: 'specialAdj',
        key: 'specialAdj',
        width: '120px',
        align: 'right',
        slots: { title: 'specialTitle', customRender: 'specialAdj' }
      },
      {
        dataIndex: 'release',
        key: 'release',
        width: '120px',
        align: 'right',
        slots: { title: 'releaseTitle', customRender: 'release' }
      }
    ]);
    const dataSearch2 = reactive<unknown[]>([]);

    // 其它卡片临时隐藏
    const caterageryCard = ref([
      {
        name: 'Program Base Accrual',
        id: 0
      },
      {
        name: 'Blue Invoice',
        id: 1
      },
      {
        name: 'MBAFC Interest',
        id: 2
      },
      {
        name: 'Dealer Stock Adj.(DS Adj.)',
        id: 3
      },
      {
        name: 'Special Adj.',
        id: 4
      },
      {
        name: 'Release',
        id: 5
      }
    ]);

    const isYearlyAvailable = ref(false);
    const judgeYearlyAvailable = () => {
      if (props.make === 'PBP') {
        dealerStockType.value = 'yearly';
        return;
      }
      if (props.makeMonth !== '12') {
        dealerStockType.value = 'quarterly';
        return;
      }
      getIsYearlyAvailable(mcId.value).then((res) => {
        isYearlyAvailable.value = res as boolean;
      });
    };

    // * 判断是dealerStock的类型： yearly / quarterly
    const dealerStockType = ref<string | null>(null);
    const isActive = ref(0);
    // 是否从Final View 跳过来
    props.activeName === 'blueInvoice' ? (isActive.value = 1) : (isActive.value = 0);
    const selectCard = (id: number) => {
      if (id === 3) {
        dealerStockType.value = null;
        judgeYearlyAvailable();
        dealerStockAccess({ mcId: props.makeId == -1 ? 1 : props.makeId }).then((res) => {
          if (res.message === 'success') {
            isActive.value = id;
          } else {
            message.error('There is no Dealer Stock business in current month');
          }
        });
      } else {
        isActive.value = id;
      }
    };
    const mcId = computed(() => (props.makeId === -1 ? 1 : props.makeId));
    provide('mcId', mcId);
    // 初始化overview表格信息
    const initOverview = () => {
      getOverview({ mcId: mcId.value }).then((res) => {
        oneTable.length = 0;
        dataSearch2.length = 0;
        twoTable.length = 0;
        oneTable.push(...res.table1);
        dataSearch2.push(...res.table3);
        twoTable.push(...res.table2);
      });
    };
    initOverview();
    const refreshOverview = () => {
      initOverview();
    };

    const handleDealerStockSelect = async (type: string) => {
      if (type === 'yearly') {
        await setCurrentProgramByYearly(mcId.value);
        dealerStockType.value = type;
      } else {
        dealerStockType.value = type;
      }
    };

    return {
      searchMarterial,
      searchVehicle,
      columnSearch,
      dataSearch,
      oneTable,
      oneTitle,
      twoTable,
      dataSearch2,
      columnSearch2,
      caterageryCard,
      selectCard,
      isActive,
      initOverview,
      refreshOverview,
      blueInvoice,
      programBase,
      dealerStockType,
      handleDealerStockSelect,
      isYearlyAvailable
    };
  }
});
