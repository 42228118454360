
import { defineComponent, ref, computed, h, resolveComponent } from 'vue'
import type { PropType } from 'vue'
import { elementSize } from '@/utils'
import Decimal from '@/utils/closing/decimal'
import moment from 'moment'
import { message } from 'ant-design-vue'
import type { InterestMonth, InterestType, InterestInfo, InterestMonthTotalAmount } from '@/views/Closing/type'
import {
  getInterestInfo as getInterestInfoAPI,
  getInterestMonthAmountInfo as getInterestMonthAmountInfoAPI,
  saveInterestInfo as saveInterestInfoAPI,
  getTopic
} from '@/API/closing'
import blobDownload from "@/utils/blobDownload";
export default defineComponent({
  props: {
    make: {
      type: String,
      default: ''
    },
    closingId: {
      type: Number,
      default: 0
    },
    makeYear: {
      type: String,
      default: ''
    },
    makeMonth: {
      type: String as PropType<InterestMonth>,
      default: ''
    },
    interestSD: {
      type: String,
      required: true,
      default: ''
    }
  },
  emits: ["refresh-table"],
  setup(props, ctx) {

    enum InputTableRowTitle {
      FloorPlan,
      PMT,
      AllocationAll,
      WsBpInterestRate
    }

    const monthes = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

    const inputData = ref([
      {
        title: 'Floor Plan',
        ...monthes.reduce((obj: Record<string, unknown>, month: string) => {
          obj[month] = {
            amount: '',
            totalLlp: '',
          }
          return obj
        }, {}),
        input: ''
      },
      {
        title: 'PMT',
        ...monthes.reduce((obj: Record<string, unknown>, month: string) => {
          obj[month] = {
            amount: '',
            totalLlp: '',
            input: ''
          }
          return obj
        }, {}),
        input: ''
      },
      {
        title: 'Allocation All',
        ...monthes.reduce((obj: Record<string, unknown>, month: string) => {
          obj[month] = ''
          return obj
        }, {}),
        input: ''
      },
      {
        title: 'WS BP Interest Rate',
        ...monthes.reduce((obj: Record<string, unknown>, month: string) => {
          obj[month] = {
            amount: '',
            input: ''
          }
          return obj
        }, {}),
        input: ''
      },
    ])

    const inputDataTotal = (type: InputTableRowTitle) => {
      return computed(() => {
        let total = new Decimal(0)
        for (let i = 0; i < 12; i++) {
          const month = moment().month(i).format('MM')
          const amount = inputData.value[type][month].amount
          if (amount !== '') {
            total = total.plus(new Decimal(amount))
          }
        }
        return total
      })
    }

    const totalFloorPlan = inputDataTotal(InputTableRowTitle.FloorPlan)

    const totalPMT = inputDataTotal(InputTableRowTitle.PMT)

    const numberSubWithPrecision = (n: string | number, p: number) => {
      if (typeof n === 'number') {
        n = n.toString()
      }
      let i = n.search(/^\.\d/)
      if (i !== -1) {
        n = n.slice(0, i)
      }
      i = n.indexOf('.')
      if (i === -1) {
        return n
      } else {
        return `${n.slice(0, i)}.${n.slice(i + 1, i + 1 + p)}`
      }
    }

// 修改框添加千分符并只保留15位小数
    const inputAddMicrometer = (amountVAT: string) => {
      let str;
      str = amountVAT.replace(/[^\-\d.]/g, "");
      if (str) {
        str = str.replace(/,/g, "");
        const hasPot = str.indexOf(".");
        if (hasPot > -1) {
          let leftNum = str.substring(0, hasPot);
          let rightNum = str.substring(hasPot + 1, str.length);
          leftNum = leftNum.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
          if (rightNum.length > 15) {
            rightNum = str.substring(hasPot + 1, hasPot + 16);
          }
          str = leftNum + "." + rightNum;
        } else {
          str = str.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
        }
        return str;
      } else {
        return "";
      }
    };
      // 千分符转化为number
    const removeMicrometer = (amountVAT: string) => {
      let number = 0;
      let str = amountVAT + "";
      str = str.replace(/,/g, "");
      if (str) {
        number = parseFloat(str);
      } else {
        number = 0;
      }
      return number.toString();
    };
    //  const amountVATBlur = (record: TreeTableData) => {
    //   record.amountVAT = inputAddMicrometer(
    //     Math.round(Number(removeMicrometer(record.amountVAT))).toString()
    //   );
    // };

    const inputTableColumnRender = (month: string) => {
      return ({ record, text, index }: {record: any; text: string; index: number}) => {
        if (index === InputTableRowTitle.AllocationAll) {
          return h('span', text)
        } else {
          if (month === props.makeMonth) {
            const inputComponent = resolveComponent('a-input')
            const props: Record<string, unknown> = {
              value: inputData.value[index].input,
              style: 'height: 24px',
              'onUpdate:value': (value: string) => {
                if (index === InputTableRowTitle.WsBpInterestRate) {
                  inputData.value[index].input = numberSubWithPrecision(value, 2)
                } else {
                  inputData.value[index].input = numberSubWithPrecision(value, 15)
                }
              },
              onFocus: () => {
                inputData.value[index].input = inputAddMicrometer(record[month].amount.toString())
              },
              onPressEnter: (e: any) => {
                e.target.blur()
              },
              onInput: () => {
                inputData.value[index].input = inputAddMicrometer(inputData.value[index].input.toString())
              },
              onBlur: () => {

                if (index === InputTableRowTitle.WsBpInterestRate) {
                  inputData.value[index].input = new Decimal(inputData.value[index].input).round(2, false)
                } else {
                  inputData.value[index].input = new Decimal(inputData.value[index].input).round(0, false)
                }
                  inputData.value[index].input = inputAddMicrometer(inputData.value[index].input.toString())
                record[month].amount = inputData.value[index].input
              }
            }
            if (index === InputTableRowTitle.WsBpInterestRate) {
              props.suffix = '%'
            }
            return h(inputComponent, props)
          } else {
            if (index === InputTableRowTitle.WsBpInterestRate) {
              return h('span', record[month].amount === '' ? '' : `${new Decimal(record[month].amount).round(2)}%`)
            }
            return h('span', record[month].amount === '' ? '' : new Decimal(record[month].amount).round(0))
          }
        }
      }
    }

    const inputTableTotalColumnRender = ({ index }: {index: number}) => {
      if (index === InputTableRowTitle.FloorPlan) {
        return h('span', totalFloorPlan.value.round(0))
      } else if (index == InputTableRowTitle.PMT) {
        return h('span', totalPMT.value.round(0))
      } else {
        return null
      }
    }

    const inputTableColumns = ref([
      {
        dataIndex: 'title',
        width: 150,
        align: 'left',
        // fixed: 'left',
        slots: { customRender: 'rowTitle' }
      },
      // eslint-disable-next-line
      // @ts-ignore
      ...monthes.map((month: string) => {
        return {
          dataIndex: month,
          title: `${props.makeYear}-${moment().month(parseInt(month) - 1).format('MM')}`,
          customRender: inputTableColumnRender(month),
          align:'right'
        }
      }),
      {
        dataIndex: 'total',
        title: `${props.makeYear}FY`,
        customRender: inputTableTotalColumnRender,
        align:'right'
      }
    ])

    const resultTableColumnRender = (column: string) => {
      return ({ text, record }: {text: string; record: any}) => {
        const attrs = {
          class: column === props.makeMonth ? 'active' : ''
        }
        let child = '';
        if (text !== undefined && text !== '') {
          if (record.percent) {
            child = `${new Decimal(text).round(2)}%`
          } else {
            child = new Decimal(text).round(0)
          }
        }
        return h('span', attrs, child)
      }
    }

    const resultTableColumns = ref([
      {
        dataIndex: 'title',
        width: 150,
        align: 'left',
        // fixed: 'left'
      },
      ...monthes.map((month: string) => {
        return {
          dataIndex: month,
          title: `${props.makeYear}-${moment().month(parseInt(month) - 1).format('MM')}`,
          // width: 150,
          customRender: resultTableColumnRender(month),
          align:'right'
        }
      }),
      {
        dataIndex: 'total',
        title: `${props.makeYear}FY`,
        // width: 150,
        customRender: resultTableColumnRender('total'),
        align:'right'
      }
    ])

    type ResultDataItem =  {
      title: string;
      percent: boolean;
      children?: ResultDataItem[];
    } & {
      [key in InterestMonth | 'total']?: string;
    }

    const resultData = computed(() => {
      
      const totalSdData: ResultDataItem = {
        title: 'Total SD',
        percent: false
      }
      const floorPlanData: ResultDataItem = {
        title: 'Floor Plan',
        percent: false
      }
      const pmtData: ResultDataItem = {
        title: 'PMT',
        percent: false
      }
      const totalSdPercentData: ResultDataItem = {
        title: 'Total SD%',
        percent: true
      }
      const floorPlanPercentData: ResultDataItem = {
        title: 'Floor Plan',
        percent: true
      }
      const pmtPercentData: ResultDataItem = {
        title: 'PMT',
        percent: true
      }
      let totalFloorPlanLlp = new Decimal(0)
      let totalPmtLlp = new Decimal(0)
      for (let i = 0; i < 12; i++) {
        const month = moment().month(i).format('MM')
        const monthFloorPlanData = inputData.value[InputTableRowTitle.FloorPlan][month]
        const monthPmtData = inputData.value[InputTableRowTitle.PMT][month]
        floorPlanData[month] = monthFloorPlanData.amount
        pmtData[month] = monthPmtData.amount
        if (monthFloorPlanData.amount === '' && monthPmtData.amount === '') {
          totalSdData[month] = ''
        } else {
          totalSdData[month] = (new Decimal(monthFloorPlanData.amount))
            .plus(new Decimal(monthPmtData.amount)).fixed(15, false)
        }
        if (monthFloorPlanData.totalLlp !== '') {
          totalFloorPlanLlp = totalFloorPlanLlp.plus(new Decimal(monthFloorPlanData.totalLlp))
          const amount = new Decimal(monthFloorPlanData.amount)
          const totalLlp = new Decimal(monthFloorPlanData.totalLlp)
          let percent = new Decimal(0)
          if (!totalLlp.isZero()) {
            percent = amount.div(totalLlp)
          }
          floorPlanPercentData[month] = percent.times(new Decimal(100)).fixed(15, false)
        }
        if (monthPmtData.totalLlp !== '') {
          totalPmtLlp = totalPmtLlp.plus(new Decimal(monthPmtData.totalLlp))
          const amount = new Decimal(monthPmtData.amount)
          const totalLlp = new Decimal(monthPmtData.totalLlp)
          let percent = new Decimal(0)
          if (!totalLlp.isZero()) {
            percent = amount.div(totalLlp)
          }
          pmtPercentData[month] = percent.times(new Decimal(100)).fixed(15, false)
        }
        if (totalSdData[month] && (monthFloorPlanData.totalLlp !== '' || monthPmtData.totalLlp !== '')) {
          totalSdPercentData[month] = new Decimal(new Decimal(floorPlanPercentData[month]).round(5, false))
            .plus(new Decimal(new Decimal(pmtPercentData[month]).round(5))).fixed(15, false)
        } else {
          totalSdPercentData[month] = ''
        }
      }
      floorPlanData.total = totalFloorPlan.value.fixed(15, false)
      pmtData.total = totalPMT.value.fixed(15, false)
      totalSdData.total = new Decimal(floorPlanData.total)
        .plus(new Decimal(pmtData.total)).fixed(15, false)
      totalSdData.children = [ floorPlanData, pmtData ]
      floorPlanPercentData.total = totalFloorPlanLlp.isZero() ? '0' : totalFloorPlan.value.div(totalFloorPlanLlp).times(new Decimal(100)).fixed(15, false)
      pmtPercentData.total = totalPmtLlp.isZero() ? '0' : totalPMT.value.div(totalPmtLlp).times(new Decimal(100)).fixed(15, false)
      totalSdPercentData.total = new Decimal(new Decimal(floorPlanPercentData.total).round(5))
        .plus(new Decimal(new Decimal(pmtPercentData.total).round(5))).fixed(15, false)
      totalSdPercentData.children = [ floorPlanPercentData, pmtPercentData ]
      console.log(totalSdData)
      console.log(1111)
      return  [ totalSdData, totalSdPercentData ]
    })
    const isTable=ref(false)
    const initInfoData = () => {
      return getInterestInfoAPI({mcId: props.closingId}).then((res: InterestInfo) => {
        isTable.value=true;
        const floor = res.floor
        if (floor) {
          for (const month in floor) {
            inputData.value[InputTableRowTitle.FloorPlan][month].id = floor[month].id
            inputData.value[InputTableRowTitle.FloorPlan][month].amount = floor[month].amount
            inputData.value[InputTableRowTitle.FloorPlan][month].totalLlp = floor[month].totalLlp
          }
        }
        const pmt = res.pmt
        if (pmt) {
          for (const month in pmt) {
            inputData.value[InputTableRowTitle.PMT][month].id = pmt[month].id
            inputData.value[InputTableRowTitle.PMT][month].amount = pmt[month].amount
            inputData.value[InputTableRowTitle.PMT][month].totalLlp = pmt[month].totalLlp
            inputData.value[InputTableRowTitle.AllocationAll][month] = pmt[month].pmtAllStatus ? 'Y' : ''
          }
        }

        const wsbp = res.wsbp
        if (wsbp) {
          for (const month in wsbp) {
            inputData.value[InputTableRowTitle.WsBpInterestRate][month].id = wsbp[month].id
            inputData.value[InputTableRowTitle.WsBpInterestRate][month].amount = wsbp[month].amount
          }
        }
        inputData.value[InputTableRowTitle.FloorPlan].input = new Decimal(inputData.value[InputTableRowTitle.FloorPlan][props.makeMonth].amount).round(0)
        inputData.value[InputTableRowTitle.PMT].input = new Decimal(inputData.value[InputTableRowTitle.PMT][props.makeMonth].amount).round(0)
        inputData.value[InputTableRowTitle.WsBpInterestRate].input = new Decimal(inputData.value[InputTableRowTitle.WsBpInterestRate][props.makeMonth].amount).round(2)
        return getInterestMonthAmountInfoAPI(props.closingId)
      }).then((res: InterestMonthTotalAmount) => {
        inputData.value[InputTableRowTitle.FloorPlan][props.makeMonth].totalLlp = res.totalLlp
        inputData.value[InputTableRowTitle.PMT][props.makeMonth].totalLlp = res.totalLlp
        inputData.value[InputTableRowTitle.AllocationAll][props.makeMonth] = res.totalLlp === res.pmtLlp ? 'Y' : ''
      })
    }

    initInfoData()

    // const enableSave = computed(() => {
    //   return inputData.value[InputTableRowTitle.FloorPlan][props.makeMonth].amount !== '' &&
    //     // inputData.value[InputTableRowTitle.PMT][props.makeMonth].amount !== '' &&
    //     inputData.value[InputTableRowTitle.WsBpInterestRate][props.makeMonth].amount !== ''
    // })

    const handleSave = () => {
      const params = [
        {
          id: inputData.value[InputTableRowTitle.FloorPlan][props.makeMonth].id,
          year: props.makeYear,
          month: props.makeMonth,
          interestType: 'floor' as InterestType,
          amount: inputData.value[InputTableRowTitle.FloorPlan][props.makeMonth].amount as string ? inputData.value[InputTableRowTitle.FloorPlan][props.makeMonth].amount : 0,
          make: props.make,
          pmtAllStatus: false,
          totalLlp: new Decimal(inputData.value[InputTableRowTitle.FloorPlan][props.makeMonth].totalLlp).isZero() ?
            inputData.value[InputTableRowTitle.PMT][props.makeMonth].totalLlp as string :
            inputData.value[InputTableRowTitle.FloorPlan][props.makeMonth].totalLlp as string,
          mcId: props.closingId
        },
        {
          id: inputData.value[InputTableRowTitle.PMT][props.makeMonth].id,
          year: props.makeYear,
          month: props.makeMonth,
          interestType: 'pmt' as InterestType,
          amount: inputData.value[InputTableRowTitle.PMT][props.makeMonth].amount as string? inputData.value[InputTableRowTitle.PMT][props.makeMonth].amount : 0,
          make: props.make,
          pmtAllStatus: inputData.value[InputTableRowTitle.AllocationAll][props.makeMonth] === 'Y',
          totalLlp: inputData.value[InputTableRowTitle.PMT][props.makeMonth].totalLlp === '' ? '0' :
            inputData.value[InputTableRowTitle.PMT][props.makeMonth].totalLlp as string,
          mcId: props.closingId
        },
        {
          id: inputData.value[InputTableRowTitle.WsBpInterestRate][props.makeMonth].id,
          year: props.makeYear,
          month: props.makeMonth,
          interestType: 'wsbp' as InterestType,
          amount: inputData.value[InputTableRowTitle.WsBpInterestRate][props.makeMonth].amount as string? inputData.value[InputTableRowTitle.WsBpInterestRate][props.makeMonth].amount : 0,
          make: props.make,
          pmtAllStatus: false,
          totalLlp: '0',
          mcId: props.closingId
        }
      ]
      params.forEach(item => {
        item.amount = removeMicrometer(item.amount)
      })
      saveInterestInfoAPI(params).then(() => {
        return initInfoData()
      }).then(() => {
        ctx.emit("refresh-table")
        message.success('Save successfully!')
      })
    }
  // INPUT 文字提示
    const inputText = ref("");
    getTopic({ page: "INTEREST" }).then(res => {
      inputText.value = res;
    });
    const tableSize = elementSize('.ant-table-wrapper')
       const handleExport = () => {
      const params = {
        url: `/pcapi/closing/interest/exportInterest`,
        params: {
          mcId: props.closingId,
        },
      };
      blobDownload(params);
      
    };
    return {
      inputTableColumns,
      inputData,
      totalFloorPlan,
      totalPMT,
      resultTableColumns,
      resultData,
      // enableSave,
      handleSave,
      tableSize,
      inputText,
      isTable,
      handleExport
    }
  }
})
