
import { defineComponent, ref, reactive } from "vue";
import { getPBPData, savePBPData, seachVersion } from "@/API/closing";
import { QueryListType } from "@/views/Closing/type";
import deepClone from "@/utils/deepClone";
import { message } from "ant-design-vue";
const columns = reactive([
  { title: "Make", dataIndex: "make", width: "5%" },
  { title: "Brand", dataIndex: "brand", width: "7%" },
  { title: "Type Class", dataIndex: "typeClass", width: "8%" },
  { title: "Model", dataIndex: "model", width: "18%" },
  {
    title: "WS Volume",
    dataIndex: "wsVolume",
    width: "10%",
    align: "right",
    slots: { customRender: "wsVolume" }
  },
  {
    title: "WS LLP",
    dataIndex: "wsllp",
    width: "14%",
    align: "right",
    slots: { customRender: "wsllp" }
  },
  {
    title: "Update WS Volume",
    dataIndex: "updateWsVolume",
    width: "14%",
    align: "right",
    slots: { customRender: "updateWsVolume" }
  },
  {
    title: "Update WS LLP",
    dataIndex: "updateWsllp",
    width: "15%",
    align: "right",
    slots: { customRender: "updateWsllp" }
  },
  {
    title: "RT Volume",
    dataIndex: "rtVolume",
    width: "8%",
    align: "right",
    slots: { customRender: "rtVolume" }
  },
  {
    title: "Dealer Stock Volume",
    dataIndex: "dlrVolume",
    width: "14%",
    align: "right",
    slots: { customRender: "dlrVolume" }
  }
]);
const tableHeight = window.innerHeight - 430;
export default defineComponent({
  props: {
    makeId: {
      default: -1,
      type: Number
    }
  },
  setup(props,{ emit }) {
    const goBack=()=>{
       emit("show-pbp-false");
    }
    // 修改数据初始化
    const paramsData = reactive<QueryListType>({
      COPAwsVolume: "",
      COPAwsllp: "",
      BIwsVolume: "",
      BIwsllp: "",
      GapwsVolume: "",
      Gapwsllp: "",
      updateWsVolume: "",
      updateWsllp: ""
    });
    const BIwsllpTitle = ref<string>("");
    const GapwsllpTitle = ref<string>("");
    const GapwsllpRed = ref<boolean>(false);
    // 复选框初始化
    const checked = ref<boolean>(false);
    // 表格数据初始化
    const queryListData = ref<any>([]);
    let queryListTmpData: any = [];
    let allData: any = [];
    let changeData: any = [];
    // 可修改数据
    // let beforeData: any = [];
    // 全部数据
    let tempData: any = [];
    // 表格行展开
    const expandedRowKeys = ref<any>([]);
    const copaId = ref<number>(0);

    const finalVersion = ref(false);
    seachVersion({ mcId: props.makeId }).then(res => {
      finalVersion.value = res.finalVersion;
    });
    // 获取数据所有id
    const getAllId = () => {
      const arr: any = [];
      const data = queryListTmpData;
      data.length &&
        data.forEach((item: any) => {
          if (item.children && item.children.length) {
            arr.push(item.id);
            item.children.forEach((item: any) => {
              if (item.children && item.children.length) {
                arr.push(item.id);
                item.children.forEach((item: any) => {
                  arr.push(item.id);
                });
              }
            });
          }
        });
      return arr;
    };

    // 获取到Type Class层级所有数据id
    const getTypeClassId = () => {
      const arr: any = [];
      const data = queryListTmpData;
      data.length &&
        data.forEach((item: any) => {
          arr.push(item.id);
          if (item.children && item.children.length) {
            arr.push(item.id);
            item.children.forEach((item: any) => {
              arr.push(item.id);
            });
          }
        });
      return arr;
    };

    // 获取arr格式所有数据
    const getAllData = () => {
      const arr: any = [];
      const data = allData;
      data.length &&
        data.forEach((item: any) => {
          if (item.children && item.children.length) {
            arr.push(item);
            item.children.forEach((ele: any) => {
              if (ele.children && ele.children.length) {
                arr.push(ele);
                ele.children.forEach((el: any) => {
                  if (el.children && el.children.length) {
                    arr.push(el);
                    el.children.forEach((e: any) => {
                      arr.push(e);
                    });
                  }
                });
              }
            });
          }
        });
      return arr;
    };

    // 展开type class层级
    const expandTypeClass = () => {
      const data = getTypeClassId();
      Object.assign(expandedRowKeys.value, data);
    };

    // 展开折叠
    const expandModel = () => {
      const data = getAllId();
      if (checked.value) {
        Object.assign(expandedRowKeys.value, data);
      } else {
        expandedRowKeys.value = [];
        expandTypeClass();
      }
    };

    // 添加千分符保留两位小数
    const addWsllpMicrometer = (wsllp: any) => {
      let str;
      str = wsllp + "";
      if (str) {
        str = str.replace(/,/g, "");
        if (str.indexOf(".") > -1) {
          str = parseFloat(str).toFixed(2);
        }
        str = parseFloat(str) + "";
        str = str.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
        return str;
      } else {
        return "";
      }
    };

    // 添加千分符取整
    const addMicrometer = (wsllp: any) => {
      let str;
      str = wsllp + "";
      if (str) {
        str = str.replace(/,/g, "");
        str = Math.round(parseFloat(str)) + "";
        str = str.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
        return str;
      } else {
        return "";
      }
    };

    // 修改框添加千分符并只保留15位小数
    const inputAddWsllpMicrometer = (wsllp: any) => {
      let str;
      str = wsllp + "";
      if (str) {
        str = str.replace(/,/g, "");
        const hasPot = str.indexOf(".");
        if (hasPot > -1) {
          let leftNum = str.substring(0, hasPot);
          let rightNum = str.substring(hasPot + 1, str.length);
          leftNum = leftNum.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
          if (rightNum.length > 15) {
            rightNum = str.substring(hasPot + 1, hasPot + 16);
          }
          str = leftNum + "." + rightNum;
        } else {
          str = str.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
        }
        return str;
      } else {
        return "";
      }
    };

    // 修改框添加千分符取整
    const inputAddMicrometer = (wsllp: any) => {
      let str;
      str = wsllp + "";
      if (str) {
        str = str.replace(/,/g, "");
        str = parseFloat(str) + "";
        str = str.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
        return str;
      } else {
        return "";
      }
    };

    // 千分符转化为number
    const removeWsllpMicrometer = (wsllp: any) => {
      let number = 0;
      let str = wsllp + "";
      str = str.replace(/,/g, "");
      if (str) {
        number = parseFloat(str);
      } else {
        number = 0;
      }
      return number;
    };

    // 去除千分符
    const removeMicrometer = (wsllp: any) => {
      let str = wsllp + "";
      str = str.replace(/,/g, "");
      return str;
    };

    // 数据wsllp加千分符
    const dataWsllpMicrometer = () => {
      queryListData.value.length &&
        queryListData.value.forEach((item: any) => {
          item.wsVolume = addMicrometer(item.wsVolume);
          item.wsllp = addMicrometer(item.wsllp);
          if (item.children && item.children.length) {
            item.children.forEach((item: any) => {
              item.wsVolume = addMicrometer(item.wsVolume);
              item.wsllp = addMicrometer(item.wsllp);
              if (item.children && item.children.length) {
                item.children.forEach((item: any) => {
                  item.wsVolume = addMicrometer(item.wsVolume);
                  item.wsllp = addMicrometer(item.wsllp);
                });
              }
            });
          }
        });
    };

    // 判断是否是数字
    const isNumber = (num: any) => {
      if (num === "" || num === null || num === undefined) {
        return false;
      }
      return !isNaN(num);
    };

    // 减法计算
    const accSub = (num1: number, num2: number) => {
      let r1: any = "";
      let r2: any = "";
      let m: any = "";
      let n: any = "";
      try {
        r1 = num1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
      n = r1 >= r2 ? r1 : r2;
      return ((num1 * m - num2 * m) / m).toFixed(n);
    };

    // 加法计算
    const accAdd = (num1: number, num2: number) => {
      let r1: any = "";
      let r2: any = "";
      let m: any = "";
      let c: any = "";
      try {
        r1 = num1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      c = Math.abs(r1 - r2);
      m = Math.pow(10, Math.max(r1, r2));
      if (c > 0) {
        const cm = Math.pow(10, c);
        if (r1 > r2) {
          num1 = Number(num1.toString().replace(".", ""));
          num2 = Number(num2.toString().replace(".", "")) * cm;
        } else {
          num1 = Number(num1.toString().replace(".", "")) * cm;
          num2 = Number(num2.toString().replace(".", ""));
        }
      } else {
        num1 = Number(num1.toString().replace(".", ""));
        num2 = Number(num2.toString().replace(".", ""));
      }
      return (num1 + num2) / m;
    };

    let backUpAwsllp = "";
    const initData = async () => {
      await getPBPData({ mcId: props.makeId }).then(res => {
        paramsData.BIwsVolume = addWsllpMicrometer(res.aggVOs[0].wsVolume);
        paramsData.updateWsVolume = addWsllpMicrometer(
          res.aggVOs[0].updateWsVolume
        );
        BIwsllpTitle.value = res.aggVOs[0].wsllp;
        paramsData.updateWsllp = addWsllpMicrometer(res.aggVOs[0].updateWsllp);
        paramsData.BIwsllp = addMicrometer(res.aggVOs[0].wsllp);
        if (res.copaWsVolume) {
          paramsData.COPAwsVolume = addWsllpMicrometer(res.copaWsVolume);
          const num1 = removeWsllpMicrometer(paramsData.COPAwsVolume);
          const num2 = removeWsllpMicrometer(paramsData.updateWsVolume);
          const gap = parseFloat(accSub(num1, num2));
          if (gap == 0) {
            paramsData.GapwsVolume = "";
          } else {
            paramsData.GapwsVolume = addWsllpMicrometer(gap);
          }
        }
        if (res.copaWslLp) {
          backUpAwsllp = paramsData.COPAwsllp = addWsllpMicrometer(
            res.copaWslLp
          );
          // const num1 = removeWsllpMicrometer(paramsData.COPAwsllp);
          const num2 = removeWsllpMicrometer(paramsData.updateWsllp);      
          // const temp: number =parseInt(removeMicrometer(res.copaWslLp)) 
          const numBi = removeWsllpMicrometer(paramsData.COPAwsllp);  
          const gap = parseFloat(accSub(numBi,num2));
          if (gap == 0) {
            GapwsllpTitle.value = "";
            paramsData.Gapwsllp = "";
            GapwsllpRed.value = false;
          } else {
            GapwsllpTitle.value = inputAddWsllpMicrometer(gap);
            paramsData.Gapwsllp = addMicrometer(gap);
            GapwsllpRed.value = true;
          }
        }
        copaId.value = res.copaId;
        queryListTmpData = deepClone(res.aggVOs);
        queryListData.value = deepClone(res.aggVOs);
        const generate = (arr: any[]) => {
          arr.forEach(item => {
            if (item.children?.length > 0) {
              generate(item.children);
            } else {
              item.brand = "";
              item.typeClass = "";
              item.make = "";
            }
          });
        };
        generate(queryListData.value);
        allData = deepClone(res.aggVOs);
      });
      tempData = getAllData();
      changeData = deepClone(tempData);
      dataWsllpMicrometer();
      expandTypeClass();
    };
    initData();

    // 因expandedRowKeys设置之后展开行会有问题 所以重写点击行逻辑
    const onExpand = (expanded: boolean, record: any) => {
      if (expanded) {
        // 设置展开窗Key，代表展开操作
        expandedRowKeys.value.push(record.id);
      } else {
        // 代表折叠操作
        if (expandedRowKeys.value.length) {
          expandedRowKeys.value = expandedRowKeys.value.filter((v: any) => {
            return v !== record.id;
          });
        }
      }
    };

    // COPAwsVolumeChange
    const COPAwsVolumeChange = (e: Event) => {
      if (e.target && (e.target as any).value) {
        paramsData.COPAwsVolume = addMicrometer(paramsData.COPAwsVolume);
        const num1 = removeWsllpMicrometer(paramsData.COPAwsVolume);
        const num2 = removeWsllpMicrometer(paramsData.updateWsVolume);
        const gap = parseFloat(accSub(num1, num2));
        if (gap == 0) {
          paramsData.GapwsVolume = "";
        } else {
          paramsData.GapwsVolume = addWsllpMicrometer(gap);
        }
      } else {
        paramsData.GapwsVolume = "";
      }
    };
    // COPAwsllpChange
    const COPAwsllpChange = (e: Event) => {
      if (e.target && (e.target as any).value) {
        backUpAwsllp = paramsData.COPAwsllp = inputAddWsllpMicrometer(
          paramsData.COPAwsllp
        );
        const num1 = removeWsllpMicrometer(paramsData.COPAwsllp);
        const num2 = removeWsllpMicrometer(paramsData.updateWsllp);
        const gap = parseFloat(accSub(num1, num2));
        if (gap == 0) {
          GapwsllpTitle.value = "";
          paramsData.Gapwsllp = "";
          GapwsllpRed.value = false;
        } else {
          GapwsllpTitle.value = inputAddWsllpMicrometer(gap);
          paramsData.Gapwsllp = addMicrometer(gap);
          GapwsllpRed.value = true;
        }
      } else {
        GapwsllpTitle.value = "";
        paramsData.Gapwsllp = "";
        GapwsllpRed.value = false;
      }
    };
    // 计算父级的和
    const calAdd = (arg: any, key: string) => {
      let sum = 0;
      if (key === "updateWsVolume") {
        arg.forEach((item: any) => {
          sum += Number(removeMicrometer(item.updateWsVolume));
        });
      } else {
        arg.forEach((item: any) => {
          if (parseFloat(removeMicrometer(item[key])) > 0) {
            sum = accAdd(sum, parseFloat(removeMicrometer(item[key])));
          }
        });
      }
      return sum;
    };
    // 公用change调用
    const setChange = (record: any, key: string) => {
      queryListData.value.forEach((item: any) => {
        (item.children || []).forEach((ele: any) => {
          (ele.children || []).forEach((element: any) => {
            element[key] = calAdd(element.children, key);
          });
          ele[key] = calAdd(ele.children, key);
        });
        item[key] = calAdd(item.children, key);
      });
      if (key === "updateWsllp") {
        BIwsllpTitle.value = inputAddWsllpMicrometer(
          queryListData.value[0].updateWsllp
        );
        paramsData.updateWsllp = addMicrometer(
          queryListData.value[0].updateWsllp
        );
        if (paramsData.COPAwsllp) {
          paramsData.COPAwsllp = addWsllpMicrometer(paramsData.COPAwsllp);
          const num1 = removeWsllpMicrometer(paramsData.COPAwsllp);
          const num2 = removeWsllpMicrometer(
            queryListData.value[0].updateWsllp
          );
          const gap = parseFloat(accSub(num1, num2));
          GapwsllpTitle.value = inputAddWsllpMicrometer(gap);
          paramsData.Gapwsllp = addMicrometer(gap);
          GapwsllpRed.value = true;
        } else {
          GapwsllpTitle.value = "";
          paramsData.Gapwsllp = "";
          GapwsllpRed.value = false;
        }
      } else {
        paramsData.updateWsVolume = addWsllpMicrometer(
          queryListData.value[0].updateWsVolume
        );
        if (paramsData.COPAwsVolume) {
          paramsData.COPAwsVolume = addWsllpMicrometer(paramsData.COPAwsVolume);
          const num1 = removeWsllpMicrometer(paramsData.COPAwsVolume);
          const num2 = removeWsllpMicrometer(
            queryListData.value[0].updateWsVolume
          );
          const gap = parseFloat(accSub(num1, num2));
          if (gap == 0) {
            paramsData.GapwsVolume = "";
          } else {
            paramsData.GapwsVolume = addWsllpMicrometer(gap);
          }
        } else {
          paramsData.GapwsVolume = "";
        }
      }
      // dataWsllpMicrometer();
    };

    // wsVolume change时调用
    const wsVolumeChange = (record: any) => {
      record.updateWsVolume = record.updateWsVolume.trim();
      const isNum = isNumber(removeWsllpMicrometer(record.updateWsVolume));
      if (removeWsllpMicrometer(record.updateWsVolume) > 9007199254740991) {
        record.wsVolume = 9007199254740991;
      }
      if (isNum) {
        record.updateWsVolume = addMicrometer(record.updateWsVolume);
      } else {
        record.updateWsVolume = "";
      }
      setChange(record, "updateWsVolume");
    };

    // updateWsllp focus时调用
    const wsllpFocus = (record: any) => {
      changeData.length &&
        changeData.forEach((item: any) => {
          if (item.id == record.id) {
            record.updateWsllp = item.updateWsllp;
          }
        });
    };

    // updateWsllp change时调用
    const wsllpChange = (record: any) => {
      record.updateWsllp = record.updateWsllp.trim();
      const isNum = isNumber(removeWsllpMicrometer(record.updateWsllp));
      if (isNum) {
        record.updateWsllp = inputAddWsllpMicrometer(record.updateWsllp);
      } else {
        record.updateWsllp = "";
      }
      if (changeData) {
        changeData.forEach((item: any) => {
          if (item.id == record.id) {
            item.updateWsllp = record.updateWsllp;
          }
        });
      }
      setChange(record, "updateWsllp");
    };

    // wsllp blur时调用
    const wsllpBlur = (record: any) => {
      record.updateWsllp = addMicrometer(record.updateWsllp);
    };

    // save
    const save = () => {
      const arr: any = [];
      const tempArr = deepClone(queryListData.value);
      tempArr.length &&
        tempArr.forEach((item: any) => {
          item.children.forEach((el: any) => {
            el.children.forEach((element: any) => {
              element.children.forEach((ele: any) => {
                changeData.forEach((c: any) => {
                  if (ele.id === c.id) {
                    ele.wsllp = parseFloat(removeMicrometer(c.updateWsllp));
                  }
                });
                ele.wsVolume = removeWsllpMicrometer(ele.updateWsVolume);
                arr.push(ele);
              });
            });
          });
        });

      const generate = (tempArr: any[]) => {
        tempArr.forEach(item => {
          if (item.children?.length > 0) {
            generate(item.children);
          } else {
            arr.forEach((element: any) => {
              if (element.id === item.id) {
                element.make = item.make;
                element.typeClass = item.typeClass;
                element.brand = item.brand;
              }
            });
          }
        });
      };
      generate(queryListTmpData);
      const params = {
        makeId: props.makeId,
        dataList: arr,
        copaId: copaId.value,
        copaWsVolume: removeWsllpMicrometer(paramsData.COPAwsVolume),
        copaWslLp: removeWsllpMicrometer(backUpAwsllp)
      };
      savePBPData(params)
        .then((res: any) => {
          if (res.code === "60000") {
            message.error(res.message);
          }
        })
        .catch(() => {
          message.error("保存失败");
        });
    };
    const COPAwsllpBlur = () => {
      paramsData.COPAwsllp = Math.round(
        Number(removeWsllpMicrometer(backUpAwsllp))
      ).toString();
      paramsData.COPAwsllp = addWsllpMicrometer(paramsData.COPAwsllp);
    };
    const COPAwsllpFocus = () => {
      paramsData.COPAwsllp = backUpAwsllp;
    };
    return {
      columns,
      paramsData,
      BIwsllpTitle,
      GapwsllpTitle,
      GapwsllpRed,
      checked,
      queryListData,
      queryListTmpData,
      isNumber,
      copaId,
      accSub,
      accAdd,
      initData,
      changeData,
      allData,
      addWsllpMicrometer,
      addMicrometer,
      inputAddWsllpMicrometer,
      inputAddMicrometer,
      removeMicrometer,
      removeWsllpMicrometer,
      expandModel,
      expandTypeClass,
      expandedRowKeys,
      onExpand,
      COPAwsVolumeChange,
      COPAwsllpChange,
      getTypeClassId,
      getAllData,
      wsVolumeChange,
      wsllpFocus,
      wsllpChange,
      wsllpBlur,
      save,
      tableHeight,
      COPAwsllpBlur,
      COPAwsllpFocus,
      finalVersion,
      goBack
    };
  }
});
