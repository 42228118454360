import GC from '@grapecity/spread-sheets'
import { NstMappingProps } from './types'

const columnsWidth = {
    "Brand": 80,
    "Type Class": 90,
    "NST Group": 150,
    "NST Group Name(IPlan)": 200,
    "NST Group Name": 200,
    "*IMPACT Code": 150,
    "*IMPACT Model Name": 200,
    "*IMPACT Name": 250,
    "Validation": 100,
    "Remark": 120,
    "Tag": 100
}

// 处理数据 
export const handlerSheetsData = (data: NstMappingProps[], isInit = true) => {
    data.forEach(item => {
        if (isInit) {
            if (item.Validation) {
                item.Validation = ""
            } else if (!item.Validation) {
                item.Validation = "!"
            }
        } else {
            if (item.Validation) {
                item.Validation = false
            } else if (!item.Validation) {
                item.Validation = true
            }
        }
    })
    return data
}

export const isMustfill = (data: NstMappingProps[]): boolean => {
    let isBools = true
    data.forEach((item) => {
        if (item['*IMPACT Code'] != '0' && !item['*IMPACT Code']) {
            isBools = false
        }
        if (item['*IMPACT Model Name'] != '0' && !item['*IMPACT Model Name']) {
            isBools = false
        }
        if (!item['Brand'] || !item['Type Class']) {
            isBools = false
        }
    })
    return isBools
}

const findReadOnly = (spread: GC.Spread.Sheets.Workbook, data: NstMappingProps[], ) => {
    const readOnlyArr = ['*IMPACT Code', '*IMPACT Model Name', 'Remark', 'Tag']
    const sheet = spread.getActiveSheet()
    let style = new GC.Spread.Sheets.Style();
    style.locked = false
    style.backColor = '#ffffff'
    const spreadNS = GC.Spread.Sheets;
    // style.backColor = '#ffff00'
    const headerArr = Object.keys(data[0])
    data.forEach((items, col) => {
        if (!items['*IMPACT Code'] || !items['*IMPACT Model Name'] || !items['Brand'] || !items['Type Class']) {
            style = new GC.Spread.Sheets.Style();
            style.locked = false
            style.backColor = '#ffff00'

        } else {
            style = new GC.Spread.Sheets.Style();
            style.locked = false
            style.backColor = '#ffffff'
        }
        headerArr.forEach((item, row) => {
            if (readOnlyArr.includes(item)) {  
                sheet.setStyle(col + 1, row - 1, style)
                sheet.getCell(col + 1, row - 1).setBorder( new spreadNS.LineBorder( '#d4d4d4' , spreadNS.LineStyle.thin),
                    { all: true }
                ); 
            }
        })
    })
}



// 设置表头及其单元格类型
const setHeaderType = (spread: GC.Spread.Sheets.Workbook, data: NstMappingProps[]) => {
    const tableColumns: GC.Spread.Sheets.Tables.TableColumn[] = []
    const headerArr = Object.keys(data[0])
    const sheet = spread.getActiveSheet()
    headerArr.forEach((item, index) => {
        let column: GC.Spread.Sheets.Tables.TableColumn
        if (item !== 'id') {
            sheet.setColumnWidth(index - 1, columnsWidth[item])
            if (item === 'Tag') {
                column = new GC.Spread.Sheets.Tables.TableColumn(index - 1, item, item, '', new GC.Spread.Sheets.CellTypes.CheckBox())
            } else {
                column = new GC.Spread.Sheets.Tables.TableColumn(index - 1, item, item)
            }
            tableColumns.push(column)
        }
    })
    return tableColumns
}

// 设置背景色和叹号文字颜色
const setRowBackColor = (spread: GC.Spread.Sheets.Workbook, rowIndex: number, data: NstMappingProps[], isNullRow = true) => {
    const headerArr = Object.keys(data[0])
    const rowLength = data.length
    const sheet = spread.getActiveSheet()
    // style.backColor = '#e6e6e6'
    headerArr.forEach((item, column) => {
        if (isNullRow) {
            const style = new GC.Spread.Sheets.Style();
            style.backColor = '#ffff00'
            sheet.setStyle(rowIndex + 1, column, style)
            if (item === 'Validation') {
                // const style = new GC.Spread.Sheets.Style();
                style.foreColor = '#f5222d'
                // style.backColor = '#ffff00'
                sheet.setStyle(rowIndex + 1, column - 1, style)
            }
        } else {
            const style = new GC.Spread.Sheets.Style();
            style.backColor = '#f0f0f0'
            sheet.setStyle(rowIndex + 1, column, style)
            if (item === 'Validation') {
                const style = new GC.Spread.Sheets.Style();
                style.backColor = '#f0f0f0'
                style.foreColor = '#f5222d'
                sheet.setStyle(rowIndex + 1, column - 1, style)
            }
        }
    })
}

// 判断是否为空行
export const isNullRowSetBg = (spread: GC.Spread.Sheets.Workbook, data: NstMappingProps[]) => {
    spread.suspendPaint();
    data.forEach((item, index: number) => {
        if (!item['*IMPACT Code'] || !item['*IMPACT Model Name'] || !item['Type Class'] || !item['Brand']) {
            setRowBackColor(spread, index, data)
        } else {
            setRowBackColor(spread, index, data, false)
        }
    })
    findReadOnly(spread, data)
    spread.resumePaint();
}

export const bindDataTable = (spread: GC.Spread.Sheets.Workbook, data: NstMappingProps[]) => {
    const sheet = spread.getActiveSheet();
    spread.suspendPaint();
    const rowLength = data.length
    const columnLength = Object.keys(data[0]).length-1
    sheet.setRowCount(rowLength + 1)
    sheet.setColumnCount(columnLength)
    const table = sheet.tables.add('tableSales', 0, 0, rowLength, columnLength, GC.Spread.Sheets.Tables.TableThemes.light1);
    table.rowFilter().filterDialogVisibleInfo().sortByColor = false;
    table.autoGenerateColumns(false);
    table.bind(setHeaderType(spread, data), '', data);
    // sheet.getRange(1, 1, 1, -1, GC.Spread.Sheets.SheetArea.viewport).backColor('#ffff00')
    // table.highlightLastColumn(true)
    // 不显示过滤按钮
    table.filterButtonVisible(9, false)
    sheet.getRange(-1, 3, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    isNullRowSetBg(spread, data)
    // sheet.setRowHeight(0, 40, GC.Spread.Sheets.SheetArea.viewport);
    for (let i = 1; i <= data.length; i++) {
        // sheet.setRowHeight(i + 1, 70, GC.Spread.Sheets.SheetArea.viewport);
        // sheet.autoFitRow(i + 1);
        // sheet.setRowHeight(i + 1, 100, GC.Spread.Sheets.SheetArea.viewport);
        sheet.autoFitRow(i + 1);
        (i % 2 === 0) && sheet.getRange(i, -1, 1, -1, GC.Spread.Sheets.SheetArea.viewport).backColor('#f0f0f0')
    }
    // 设置是否可编辑
    // findReadOnly(spread, data)
    sheet.frozenRowCount(1)
    const option = {
        allowSelectLockedCells: true,
        allowSelectUnlockedCells: true,
        allowFilter: true,
        allowSort: true,
        allowResizeRows: true,
        allowResizeColumns: true,
        allowEditObjects: false,
        allowDragInsertRows: false,
        allowDragInsertColumns: false,
        allowInsertRows: false,
        allowInsertColumns: false,
        allowDeleteRows: false,
        allowDeleteColumns: false
    };
    sheet.options.protectionOptions = option;
    spread.resumePaint();

}

export const inputEventToFormula = (spread: GC.Spread.Sheets.Workbook, data: NstMappingProps[]) => {
    const sheet = spread.getActiveSheet();
    const headerArr = Object.keys(data[0])
    const spreadNS = GC.Spread.Sheets;
    const codeIndex = headerArr.findIndex(item => item === '*IMPACT Code') - 1
    const nameIndex = headerArr.findIndex(item => item === '*IMPACT Model Name') - 1
    const modelIndex = headerArr.findIndex(item => item === '*IMPACT Name') - 1
    const validationIndex = headerArr.findIndex(item => item === 'Validation') - 1
    sheet.bind(GC.Spread.Sheets.Events.ValueChanged, (e: any, info: any) => {
        //事件挂起
        sheet.suspendEvent();
        const nstGroupValue = sheet.getCell(info.row, 2).text().substring(0, 6)
        const codeValue = sheet.getCell(info.row, codeIndex).text().substring(0, 6)
        const nameValue = sheet.getCell(info.row, nameIndex).text()
        sheet.getCell(info.row, info.col).backColor('#fff')
        sheet.getCell(info.row, info.col).setBorder( new spreadNS.LineBorder( '#d4d4d4' , spreadNS.LineStyle.thin),
            { all: true }
        ); 
        if (codeValue && nameValue) {
            sheet.setValue(info.row, modelIndex, nameValue + ' ' + '(' + codeValue + ')')
        }
        if (nstGroupValue == codeValue) {
            sheet.setValue(info.row, validationIndex, '')
            sheet.resumeEvent();
            return;
        } else {
            sheet.setValue(info.row, validationIndex, '!')
            sheet.resumeEvent();
            return;
        }
        // 恢复事件
        // sheet.resumeEvent();
    })
    sheet.bind(GC.Spread.Sheets.Events.RangeChanged, (e: any, info: any) => {
        //事件挂起
        sheet.suspendEvent();
        const spreadNS = GC.Spread.Sheets;
        info.changedCells.forEach((item: any) => {
            if (info.action === 3) {
                sheet.getCell(item.row, item.col).backColor('#fff')
                sheet.getCell(item.row, item.col).setBorder( new spreadNS.LineBorder( '#d4d4d4' , spreadNS.LineStyle.thin),
                    { all: true }
                ); 
                const nstGroupValue = sheet.getCell(item.row, 2).text().substring(0, 6)
                const codeValue = sheet.getCell(item.row, codeIndex).text().substring(0, 6)
                const nameValue = sheet.getCell(item.row, nameIndex).text()
                if (codeValue && nameValue) {
                    sheet.setValue(item.row, modelIndex, nameValue + ' ' + '(' + codeValue + ')')
                    // sheet.setValue(info.row, modelIndex, nameValue + ' ' + '(' + codeValue + ')')
                }
                if (nstGroupValue == codeValue) {
                    sheet.setValue(item.row, validationIndex, '')
                    sheet.resumeEvent();
                    return;
                } else {
                    sheet.setValue(item.row, validationIndex, '!')
                    sheet.resumeEvent();
                    return;
                }
            }
        })
        // 恢复事件
        sheet.resumeEvent();
    })
    sheet.resumeEvent();
}

export const ssHotTop = (spread: GC.Spread.Sheets.Workbook, data: any) => {
    spread.suspendPaint();
    const sheet = spread.getActiveSheet();
    // sheet是否显示x
    spread.options.tabNavigationVisible = false
    spread.options.tabStripVisible = false
    // 滚动条常用设置
    spread.options.scrollbarMaxAlign = true
    // 设置整个表格不能插入， 删除行列
    // allowInsertRows不允许插入行，allowInsertColumns不允许插入列
    // allowDeleteRows不允许删除行, allowDeleteColumns不允许删除列
    sheet.options.protectionOptions.allowInsertRows = false
    sheet.options.protectionOptions.allowInsertColumns = false
    sheet.options.protectionOptions.allowDeleteRows = false
    sheet.options.protectionOptions.allowDeleteColumns = false

    // sheet.options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.values;
    //填充铺满整个canvas
    spread.options.scrollbarMaxAlign = true;
    spread.options.scrollByPixel = true;
    //禁止拖动填充
    spread.options.allowUserDragFill = false;
    spread.options.allowUserDragDrop = false;
    //自适应的时候同时测量 header 区域和 Viewport 区域
    spread.options.autoFitType = GC.Spread.Sheets.AutoFitType.cellWithHeader;
    // 设置隐藏头和列
    sheet.options.rowHeaderVisible = false
    // sheet.options.colHeaderVisible = false
    // 要设置允保护，不允许的才能生效
    sheet.options.isProtected = true
    // 不允许单个格子编辑，是样式控制
    const sheetStyle = sheet.getDefaultStyle();
    //修改并设置表的默认样式locked为false.
    // sheetStyle.locked = false;
    sheetStyle.backColor = '#bfbfbf'
    //默认对其方式
    sheetStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
    sheetStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;
    sheet.setDefaultStyle(sheetStyle);
    //禁止缩放
    spread.options.allowUserZoom = false;


    // 设置数据
    bindDataTable(spread, data)
    // 绑定事件
    inputEventToFormula(spread, data)
    spread.resumePaint();

}


