import GC from '@grapecity/spread-sheets'
import { reactive, toRefs, onMounted,  } from 'vue';
import { KufirPanelData } from './types'
interface TablePosition {
    tableHeight: number;
}

const columnsWidth = {
    'Brand': 70,
    'Type Class': 85,
    'NST group': 160,
    'NST Group Name': 160,
    'FC Name': 180,
}

// 计算表格的高度
export const usetableHeight = (id: string, idTop?: string) => {
    // debugger
    const tablePosition = reactive<TablePosition>({tableHeight: 400});
    const resizeHandler = (): void => {
        try {
            // 获取body的高度
            const clientHeight: number = document.body.clientHeight;
            const tableOffsetTop: number = (document.querySelector(id) as HTMLDivElement).getBoundingClientRect().top;
            
            tablePosition.tableHeight = clientHeight - tableOffsetTop - 15;
            (document.querySelector(id) as HTMLDivElement).style.height = tablePosition.tableHeight + 'px'
        } catch (e) {
            console.log(e);
        }
    }
    onMounted(() => {
        resizeHandler();
        window.addEventListener('resize', resizeHandler);
    })

    return toRefs(tablePosition);

}

// 设置这一列是否需要过滤的功能 汇总的公式设置
const setFilter = (spread: GC.Spread.Sheets.Workbook, frozenIndex: number ,table: GC.Spread.Sheets.Tables.Table, data: KufirPanelData) => {
    const keyArr = Object.keys(data)
    const sheet = spread.getActiveSheet();
    for (let index = 0; index < keyArr.length; index++) {
        if (index > frozenIndex) {
            // 不显示过滤按钮
            table.filterButtonVisible(index, false)
            // 设置汇总
            table.setColumnFormula(index, `=SUBTOTAL(109,[${keyArr[index]}])`);
            sheet.getRange(-1, index, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.right)
            sheet.getCell(0, index).hAlign(GC.Spread.Sheets.HorizontalAlign.right)
            sheet.setFormatter(-1, index, "#,##0", GC.Spread.Sheets.SheetArea.viewport);
        } else if (index === frozenIndex) {
            table.setColumnValue(index, "Total");
            sheet.getRange(-1, index, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.left)
            sheet.getCell(0, index).hAlign(GC.Spread.Sheets.HorizontalAlign.left)

        }
        //  else {
        //     table.setColumnValue(index, "");
        //     sheet.getRange(-1, index, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.left)
        //     sheet.getCell(0, index).hAlign(GC.Spread.Sheets.HorizontalAlign.left)
        // }
    }
    sheet.getRange(-1, 0, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.center)
    sheet.getRange(-1, 1, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.center)
}

// 设置宽度
const setColumnWidth = (sheet: GC.Spread.Sheets.Worksheet, data: KufirPanelData) => {
    const keyArr = Object.keys(data)
    keyArr.forEach((item, index) => {
        if (!columnsWidth[item]) {
            sheet.setColumnWidth(index, 100)
        } else {
            sheet.setColumnWidth(index, columnsWidth[item])
        }
    })
}

// 绑定数据
export const bindDataTable = (spread: GC.Spread.Sheets.Workbook, data: KufirPanelData[]) => {
    // 挂起
    spread.suspendPaint();
    const sheet = spread.getActiveSheet();
    // 自定义边框样式
    const spreadNS = GC.Spread.Sheets;
    sheet.getRange(0, 0, data.length + 2, 18).setBorder(new spreadNS.LineBorder('#fff', spreadNS.LineStyle.thin), {
        all: true
    })
    sheet.setRowCount(data.length + 2);
    sheet.setColumnCount(Object.keys(data[0]).length);
    
    const table = sheet.tables.addFromDataSource("Table" , 0, 0, data, GC.Spread.Sheets.Tables.TableThemes.light1);
    table.rowFilter().filterDialogVisibleInfo().sortByColor = false;
    // 取消交替的样式
    table.bandRows(false);
    // 设置行数 表头和汇总行要多两行
    
    // 设置冻结列
    const frozenIndex = Object.keys(data[0]).findIndex(item => item === 'FC Name')
    // sheet.frozenColumnCount(frozenIndex + 2)
    // 设置冻结行 表头
    sheet.frozenRowCount(1)
    table.showFooter(true);
    table.showHeader(true);
    sheet.getRange(-1, frozenIndex + 1, -1, 1).font('bold 11pt Calibri')
    // table.highlightFirstColumn(true);
    table.highlightLastColumn(false);
    // 设置宽度
    setColumnWidth(sheet, data[0])
    sheet.options.rowHeaderVisible = false
    // sheet.autoFitColumn(3);
    // sheet.setRowHeight(0, 40, GC.Spread.Sheets.SheetArea.viewport);
    sheet.getRange(0, -1, 1, -1, GC.Spread.Sheets.SheetArea.viewport).backColor('#bfbfbf')
    sheet.getRange(data.length + 1, -1, 1, -1, GC.Spread.Sheets.SheetArea.viewport).backColor('#bfbfbf')
    sheet.setRowHeight(data.length + 1, 40, GC.Spread.Sheets.SheetArea.viewport);
    // sheet.setRowHeight(1, 40, GC.Spread.Sheets.SheetArea.viewport);

    sheet.autoFitRow(1);

    for (let i = 1; i <= data.length; i++) {
        // sheet.setRowHeight(i + 1, 40, GC.Spread.Sheets.SheetArea.viewport);
        sheet.autoFitRow(i + 1);
        (i % 2 === 0) && sheet.getRange(i, -1, 1, -1, GC.Spread.Sheets.SheetArea.viewport).backColor('#f9f9f9')

    }
    // sheet.bind(GC.Spread.Sheets.Events.EditEnded, function (e: any, info: any) {
    //     sheet.autoFitRow(info.row);
    // });
    // 设置过滤的
    setFilter(spread, frozenIndex, table, data[0])
    // 固定最后一行
    sheet.frozenTrailingRowCount(1)
    const option = {
        allowSelectLockedCells: true,
        allowSelectUnlockedCells: true,
        allowFilter: true,
        allowSort: true,
        allowResizeRows: true,
        allowResizeColumns: true,
        allowEditObjects: false,
        allowDragInsertRows: true,
        allowDragInsertColumns: true,
        allowInsertRows: false,
        allowInsertColumns: false,
        allowDeleteRows: false,
        allowDeleteColumns: false,
    };
    sheet.options.protectionOptions = option;
    
    spread.resumePaint();
}

// 初始化配置
export const ssHotTop = (spread: GC.Spread.Sheets.Workbook, data: any) => {
    spread.suspendPaint();
    const sheet = spread.getActiveSheet();
  
    // 滚动条样式
    // sheet是否显示x
    spread.options.tabNavigationVisible = false
    spread.options.tabStripVisible = false
    // 滚动条常用设置
    spread.options.scrollbarMaxAlign = true
    // 设置整个表格不能插入， 删除行列
    // allowInsertRows不允许插入行，allowInsertColumns不允许插入列
    // allowDeleteRows不允许删除行, allowDeleteColumns不允许删除列
    sheet.options.protectionOptions.allowInsertRows = false
    sheet.options.protectionOptions.allowInsertColumns = false
    sheet.options.protectionOptions.allowDeleteRows = false
    sheet.options.protectionOptions.allowDeleteColumns = false

    sheet.options.gridline.showHorizontalGridline = false
    sheet.options.gridline.showVerticalGridline = false

    sheet.options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.values;
    //填充铺满整个canvas
    spread.options.scrollbarMaxAlign = true;
    spread.options.scrollByPixel = true;
    //禁止拖动填充
    // spread.options.allowUserDragFill = false;
    // spread.options.allowUserDragDrop = false;
    //自适应的时候同时测量 header 区域和 Viewport 区域
    spread.options.autoFitType = GC.Spread.Sheets.AutoFitType.cellWithHeader;
    // 设置隐藏头和列
    // sheet.options.rowHeaderVisible = false
    // sheet.options.colHeaderVisible = false
    // sheet.options.colHeaderVisible = false
    // 要设置允保护，不允许的才能生效
    sheet.options.isProtected = true
    
    // 不允许单个格子编辑，是样式控制
    const sheetStyle = sheet.getDefaultStyle();
    //修改并设置表的默认样式locked为false.
    sheetStyle.locked = true;
    //默认对其方式
    // sheetStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
    sheetStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;
    // sheetStyle.wordWrap = true
    sheet.setDefaultStyle(sheetStyle);
    //禁止缩放
    spread.options.allowUserZoom = false;
 
    //禁止缩放
    spread.options.allowUserZoom = false;
    // 绑定数据
    if (data.length > 0) bindDataTable(spread, data)
    spread.resumePaint();
}