
import { defineComponent, reactive, ref, toRefs,onBeforeUnmount } from "vue";
import {
  getDataPre,
  importData,
  computePBPData,
  checkNSTGroup,
  getCalculate,
  initProgramInfo,
  checkMSRP,
  seachVersion,
  importImpact
} from "@/API/closing";
import downloadFile from "@/utils/blobDownload";
import CbuUpload from "@/views/Closing/Tab/Cbu/Upload.vue";
import MaintainModel from "@/views/Closing/Tab/Components/MaintainModel.vue";
import ImpactModel from "@/views/Closing/Tab/Components/ImpactModel.vue";
import { message } from "ant-design-vue";
import store from '@/store';
export default defineComponent({
  components: { CbuUpload, MaintainModel, ImpactModel },
  emits: ["handle-show-pbp", "chang-tab"],
  props: {
    make: {
      default: "",
      type: String
    },
    makeId: {
      default: 1,
      type: Number
    },
    makeYear: {
      default: "",
      type: String
    },
    makeMonth: {
      default: "",
      type: String
    },
    breadcrumbVersion: {
      default: "",
      type: String
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      dlrResult: "",
      dlrData: {
        resultMsg: "",
        importDate: "",
        result: "",
        total:'',
        ResultMsg:""
      },
      wsResult: "",
      wsData: {
        resultMsg: "",
        importDate: "",
        result: "",
        total:"",
        ResultMsg:""
      },
      rtResult: "",
      rtData: {
        resultMsg: "",
        importDate: "",
        result: "",
        total:"",
        ResultMsg:"",
      },
      uploadResult: "",
      uploadData: {
        resultMsg: "",
        importDate: "",
        result: ""
      },
      impactResult: "",
      impact: {
        resultMsg: "",
        importDate: "",
        result: ""
      },
      nstResult: "",
      nst: {
        resultMsg: "",
        importDate: "",
        result: ""
      },
      msrpResult: "",
      msrp: {
        resultMsg: "",
        importDate: "",
        result: ""
      },
      pgResult: "",
      pgData: {
        resultMsg: "",
        total:'',
        ResultMsg: "",
        
      }
    });
    const showDrawer = ref(false);
    const finalVersion = ref(false);
    seachVersion({ mcId: props.makeId }).then(res => {
      finalVersion.value = res.finalVersion;
    });
    // programs手动获取导入数据
    const initPg = () => {
      initProgramInfo(props.makeId).catch(() => {
        state.pgResult = "error";
        state.pgData = { resultMsg: "Validate error" , total:'',
        ResultMsg: "",};
      });
    };

    const changeFirstLetter=(str: any)=>{
      if (str){
        //转换为字符串数组
            const array = str.toLowerCase().split(" ")
            for (let i =0 ;i < array.length; i++){
                //给数组的每一个元素重新赋值,将首字母变为大写
                array[i]=array[i][0].toUpperCase()+array[i].substring(1,array[i].length)
            }
            const string = array.join(" ")
            return string
      }
            
        }
    
    // 定时器
    const timer = ref<number | null>(null);
    const initData = () => {
         store.commit(
        'updateIsPolling',
        false
    );
      getDataPre({ mcId: props.makeId }).then(res => {
         store.commit(
        'updateIsPolling',
        true
    );
        state.dlrData = {
          resultMsg: "",
          importDate: "",
          result: "",
          total:'',
          ResultMsg:""
        };
        state.wsData = {
          resultMsg: "",
          importDate: "",
          result: "",
          total:"",
          ResultMsg:"",
        };
        state.rtData = {
          resultMsg: "",
          importDate: "",
          result: "",
          total:"",
          ResultMsg:"",
        };
        state.uploadData = {
          resultMsg: "",
          importDate: "",
          result: ""
        };
        state.impact = {
          resultMsg: "",
          importDate: "",
          result: ""
        };
        state.nst = {
          resultMsg: "",
          importDate: "",
          result: ""
        };
        state.msrp = {
          resultMsg: "",
          importDate: "",
          result: ""
        };
        state.pgData = {
          resultMsg: "",
          ResultMsg:'',
          total:"",
        };
        Object.assign(state.wsData, res.wsData);
        if (state.wsData.total||state.wsData.total=='0'){
          state.wsData.ResultMsg=`${changeFirstLetter(state.wsData.resultMsg)} ; ${state.wsData.total.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')} Data Import`
        }
        Object.assign(state.rtData, res.rtData);
        if (state.rtData.total||state.wsData.total=='0'){
          state.rtData.ResultMsg=`${changeFirstLetter(state.rtData.resultMsg)} ; ${state.rtData.total.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')} Data Import`
        }
         Object.assign(state.dlrData, res.dlrData);
         
       
        if (state.dlrData.total||state.dlrData.total=='0'){
           state.dlrData.ResultMsg=`${changeFirstLetter(state.dlrData.resultMsg)} ; ${state.dlrData.total.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')} Data Import`
        }
       Object.assign(state.pgData, res.program);
        if (state.pgData.total||state.pgData.total=='0'){
           state.pgData.ResultMsg=`${changeFirstLetter(state.pgData.resultMsg)} ; ${state.pgData.total.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,')} Data Import`
        }
        Object.assign(state.nst, res.nst);
        
        
        Object.assign(state.msrp, res.msrp);
        Object.assign(state.uploadData, res.uploadData);
        Object.assign(state.impact, res.impact);
        state.dlrResult = res.dlrData?.result?.toLowerCase() || "";
        state.rtResult = res.rtData?.result?.toLowerCase() || "";
        state.wsResult = res.wsData?.result?.toLowerCase() || "";
        state.pgResult = res.program?.result?.toLowerCase() || "";
        state.nstResult = res.nst?.result?.toLowerCase() || "";
        state.msrpResult = res.msrp?.result?.toLowerCase() || "";
        state.uploadResult = res.uploadData?.result?.toLowerCase() || "";
        state.impactResult = res.impact?.result?.toLowerCase() || "";
        // if (
        //   state.dlrResult !== "loading" &&
        //   state.rtResult !== "loading" &&
        //   state.wsResult !== "loading" &&
        //   state.pgResult !== "loading"
        // ) {
        //   timer.value && clearInterval(timer.value);
        // }
      });
    };
    let isTemp=false;//是否第一次进入
    const initNew=()=>{
      if (isTemp){
        initData()
        timer.value = setInterval(() => {
          
        initData();
      }, 5000);
      }
      isTemp=true;
    }
    initNew();
    const handleImport = async (arg: string) => {
      // 定义变量是为了并发请求，无实际业务意义
      let resWs, resDlr, resRt, init;
      // final版本 不允许点击
      if (finalVersion.value) return;
      switch (arg) {
        case "c":
          // if (state.wsResult !== "loading") {
            state.wsResult = "loading";
            state.wsData.resultMsg = "Processing";
          // }
          // if (state.dlrResult !== "loading") {
            state.dlrResult = "loading";
            state.dlrData.resultMsg = "Processing";
          // }
          // if (state.rtResult !== "loading") {
            state.rtResult = "loading";
            state.rtData.resultMsg = "Processing";
          // }
          // if (state.pgResult !== "loading") {
            state.pgResult = "loading";
            state.pgData.resultMsg = "Processing";

            await Promise.all([
              importData({ type: "ws", mcId: props.makeId }),
              importData({ type: "dlr", mcId: props.makeId }),
              importData({ type: "rt", mcId: props.makeId })
            ]);
            init = initPg();
          // }
          break;
        case "v":
          // if (state.impactResult !== "loading") {
            state.impactResult = "loading";
            state.impact.resultMsg = "Processing";
            resWs = importImpact({ mcId: props.makeId });
          // }
          // if (state.nstResult !== "loading") {
            state.nstResult = "loading";
            state.nst.resultMsg = "Processing";
            resRt = checkNSTGroup({ mcId: props.makeId });
          // }
          // if (state.msrpResult !== "loading") {
            state.msrpResult = "loading";
            state.msrp.resultMsg = "Processing";
            init = checkMSRP({ mcId: props.makeId });
          // }
          await resWs;
          await resRt;
          await resDlr;
          await init;
          break;
        case "ws":
          // if (state.wsResult !== "loading") {
            state.wsResult = "loading";
            state.wsData.resultMsg = "Processing";
            await importData({ type: "ws", mcId: props.makeId });
          // }
          break;
        case "rt":
          // if (state.rtResult !== "loading") {
            state.rtResult = "loading";
            state.rtData.resultMsg = "Processing";
            await importData({ type: "rt", mcId: props.makeId });
          // }
          break;
        case "dlr":
          // if (state.dlrResult !== "loading") {
            state.dlrResult = "loading";
            state.dlrData.resultMsg = "Processing";
            await importData({ type: "dlr", mcId: props.makeId });
          // }
          break;
        case "nst":
          // if (state.nstResult !== "loading") {
            state.nstResult = "loading";
            state.nst.resultMsg = "Processing";
            await checkNSTGroup({ mcId: props.makeId });
          // }
          break;
        case "pg": //添加programbase的初始化请求
          // if (state.pgResult !== "loading") {
            state.pgResult = "loading";
            state.pgData.resultMsg = "Processing";
            await initPg();
          // }
          break;
        case "msrp": //添加MSRP的初始化请求
          // if (state.msrpResult !== "loading") {
            state.msrpResult = "loading";
            state.msrp.resultMsg = "Processing";
            await checkMSRP({ mcId: props.makeId });
          // }
          break;
        case "impact": //添加MSRP的初始化请求
          // if (state.impactResult !== "loading") {
            state.impactResult = "loading";
            state.impact.resultMsg = "Processing";
            await importImpact({ mcId: props.makeId });
          // }
          break;
        default:
          break;
      }
      // timer.value = setInterval(() => {
      //   initData();
      // }, 5000);
    };

    const handleExport = (arg: string) => {
      const url = "/pcapi/closing/dataPreparation/export";
      downloadFile({ url, params: { type: arg, mcId: props.makeId } });
    };
    const handlePbp = async () => {
      const res = await computePBPData({ mcId: props.makeId });
      if (res.code === "0") {
        emit("handle-show-pbp");
      } else {
        message.error("Failed");
      }
    };
    const handleUpload = () => {
      showDrawer.value = true;
    };
    const closeDrawer = () => {
      showDrawer.value = false;
    };
    const handleUploadSuccess = () => {
      initData();
    };
    const handleCalculate = async () => {
      const res = await getCalculate({ mcId: props.makeId });
      res.code === "0" &&
        emit(
          "chang-tab",
          "GenerateClosing",
          props.make,
          props.breadcrumbVersion,
          props.makeId,
          props.makeMonth,
          props.makeYear
        );
    };
    // 模态框事件
    const visible = ref<boolean>(false);
    const handleModel = (): void => {
      visible.value = true;
    };
    const impactVisible = ref<boolean>(false);
    // IMPACT Maintain弹窗
    const handleImpact = (): void => {
      impactVisible.value = true;
    };
    onBeforeUnmount(()=>{
      clearInterval(timer.value as any);
    })
    return {
      ...toRefs(state),
      handlePbp,
      initData,
      handleExport,
      handleImport,
      handleUpload,
      showDrawer,
      closeDrawer,
      handleUploadSuccess,
      handleCalculate,
      visible,
      handleModel,
      handleImpact,
      checkNSTGroup,
      initProgramInfo,
      impactVisible,
      timer,
      finalVersion,
      initNew
    };
  }
});
