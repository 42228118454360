
import { defineComponent, ref, computed } from 'vue';
import { getSubInfo, getSpecBrand, getTopicName, getTreeTable, getTopic, postSaveData } from '@/API/closing';
import { message } from 'ant-design-vue';
import { TreeTableData } from '@/views/Closing/type';
import blobDownload from '@/utils/blobDownload';
const tableHeight = window.innerHeight - 440;
interface BrandType {
  id: string;
  isSelect: boolean;
}

interface AllocationDataReqsType {
  amount: string;
  brand: string;
  llp: string;
  make: string;
  model: string;
  typeClass: string;
  volume: number;
}

interface ParamsDataType {
  adjustmentNo: string;
  allocationAmount: string;
  allocationDataReqs: AllocationDataReqsType[];
  mcId: number;
  name: string;
  allocationType: number;
  topicCode: string;
  type: number;
}

export default defineComponent({
  props: {
    makeId: {
      type: Number,
      required: true
    },
    make: {
      type: String,
      required: true
    }
  },
  emits: ['refresh-overview'],
  setup(props, { emit }) {
    const checkFlag = ref('Allocation');
    const dataTitle = ref('Total');
    const tableData = ref([]);
    // 中间部分表格
    const columns = ref([]);
    const totalTable = ref([]);
    // tree table data
    const tempData = ref<TreeTableData[]>([]);
    // Brand
    const brandArray = ref<BrandType[]>([]);
    // 选择的Topic
    const topic = ref('');
    const topicArray = ref([]);
    const name = ref('');

    const brandColumns = ref([
      {
        dataIndex: 'operation',
        key: 'operation',
        width: '30px',
        slots: { title: 'operation', customRender: 'checked' }
      },
      {
        title: 'Vehicle',
        dataIndex: 'make',
        key: 'make',
        width: '80px',
        align: 'left'
      },
      {
        title: '',
        dataIndex: 'brand',
        width: '100px',
        key: 'brand',
        align: 'center'
      },
      {
        title: '',
        dataIndex: 'typeClass',
        width: '100px',
        key: 'typeClass',
        align: 'center'
      },
      {
        title: '',
        dataIndex: 'model',
        key: 'model',
        width: '400px',
        align: 'left'
      },
      {
        title: 'WS Volume',
        dataIndex: 'volume',
        key: 'volume',
        align: 'right'
      },
      {
        title: 'WS LLP',
        dataIndex: 'llp',
        key: 'llp',
        align: 'right'
      },
      {
        title: 'Avg. SI%',
        dataIndex: 'avgSi',
        key: 'avgSi',
        align: 'right'
      },
      {
        title: 'Amount w/o VAT',
        dataIndex: 'amountVAT',
        key: 'amountVAT',
        align: 'right',
        width: '180px',
        slots: { customRender: 'amount' }
      }
    ]);
    if (props.make === 'VAN') {
      brandColumns.value.forEach((item) => {
        item.title = item.title?.replace('WS', 'RT');
        if (item.title === 'WS Volume') {
          item.title = 'RT Volume';
        }
        if (item.title === 'WS LLP') {
          item.title = 'RT LLP';
        }
      });
    }

    const initData = async () => {
      const res = await getSubInfo({ mcId: props.makeId });
      totalTable.value = res.totalTable;
      columns.value = res.tableHeaders;
      tableData.value = res.subOverviewItemDtos;
    };
    initData();
    // 获取Topic & Name
    const getTopicAndName = async () => {
      const res = await getTopicName({
        mcId: props.makeId,
        adjustmentNo: '',
        type: 2
      });
      topicArray.value = res.topicList;
      name.value = res.name;
    };
    getTopicAndName();
    // INPUT 文字提示
    const inputText = ref('');
    // Allocation Input Number
    const inputNumber = ref();
    getTopic({ page: 'RELEASE' }).then((res) => {
      inputText.value = res;
    });
    const data = computed(() => tempData.value);
    // 表格全选按钮
    const allFlag = ref(false);
    // 判断复选表头
    const checkAllFlag = () => {
      allFlag.value = tempData.value.length > 0;
      tempData.value.forEach((item) => {
        if (!item.checked) {
          allFlag.value = false;
          return;
        }
      });
    };
    // 判断父级 复选框 是否勾选
    const checkParent = (arr: TreeTableData[]) => {
      arr.forEach((item) => {
        item.children?.forEach((el) => {
          if (!el.checked) {
            item.checked = false;
            allFlag.value = false;
          }
          if (item.children?.length > 0) checkParent(item.children);
          // 祖先 后代  后代勾选，递归判断
          item.checked = item.children?.every((el) => el.checked);
        });
        if (item.children?.length > 0) checkParent(item.children);
      });
    };
    const changeChecked = (record: TreeTableData) => {
      const generate = (record: TreeTableData) => {
        if (record.children) {
          record.children?.forEach((item: TreeTableData) => {
            item.checked = record.checked;
            if (item.children && item.children.length > 0) generate(item);
          });
        }
      };
      generate(record);
      checkParent(tempData.value);
      // 判断复选表头
      checkAllFlag();
    };

    // Tree Table Data
    let backUpTableData: any[];
    const selectAll = () => {
      const generate = (arr: TreeTableData[]) => {
        arr.forEach((item) => {
          // 去除小数点后面0
          item.amountVAT = item.amountVAT.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1');
          // 显示整数
          item.amountVAT = Math.round(parseFloat(item.amountVAT)).toString();
          item.checked = allFlag.value;
          if (item.children?.length > 0) {
            generate(item.children);
          }
        });
      };
      generate(tempData.value);
      checkParent(tempData.value);
      // 判断复选表头
      checkAllFlag();
    };
    const changeCheckFlag = () => {
      allFlag.value = checkFlag.value === 'Allocation';
      selectAll();
      // inputNumber.value = "";
    };
    // Expend复选框初始化
    const checked = ref<boolean>(false);
    // 表格行展开
    const expandedRowKeys = ref<any>([]);
    // 获取Tree Data数据所有id
    const getAllId = () => {
      const tempArray: number[] = [];
      const generate = (arr: TreeTableData[]) => {
        arr.forEach((item: TreeTableData) => {
          tempArray.push(item.id);
          if (item.children?.length > 0) {
            generate(item.children);
          }
        });
      };
      generate(tempData.value);
      return tempArray;
    };
    // 获取到Type Class层级所有数据id
    const getTypeClassId = () => {
      const tempArray: number[] = [];
      const generate = (arr: TreeTableData[]) => {
        arr.forEach((item: TreeTableData) => {
          tempArray.push(item.id);
          // 展开到model
          if (item.children[0].children[0].children?.length > 0) {
            generate(item.children);
          }
        });
      };
      generate(tempData.value);
      return tempArray;
    };
    // 展开type class层级
    const expandTypeClass = () => {
      const data = getTypeClassId();
      Object.assign(expandedRowKeys.value, data);
    };

    // 展开折叠
    const expandModel = () => {
      const data = getAllId();
      if (checked.value) {
        Object.assign(expandedRowKeys.value, data);
      } else {
        expandedRowKeys.value = [];
        expandTypeClass();
      }
    };
    // 因expandedRowKeys设置之后展开行会有问题 所以重写点击展开行逻辑
    const onExpand = (expanded: boolean, record: any) => {
      if (expanded) {
        // 设置展开窗Key，代表展开操作
        expandedRowKeys.value.push(record.id);
      } else {
        // 代表折叠操作
        if (expandedRowKeys.value.length) {
          expandedRowKeys.value = expandedRowKeys.value.filter((v: any) => {
            return v !== record.id;
          });
        }
      }
    };
    let isBrand = false;
    // Tree Table
    const getTreeTableData = async () => {
      let brands: string[] = [];
      brandArray.value.forEach((item) => {
        if (item.isSelect) {
          brands.push(item.id);
        }
      });
      brands = brands.length > 0 ? brands : [''];

      const res = await getTreeTable({
        mcId: props.makeId,
        adjustmentNo: '',
        type: 2,
        brands: brands
      });
      tempData.value = res.commonTreeVOS;
      // topic.value = res.topicCode;
      if (!isBrand) {
        name.value = res.name;
        inputNumber.value = res.allocationAmount === null ? '' : res.allocationAmount;
      }
      backUpTableData = [];
      isBrand = false;
      const generate = (arr: TreeTableData[]) => {
        arr.forEach((item) => {
          // 去除小数点后面0
          item.amountVAT = item.amountVAT.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1');
          // item.checked = allFlag.value;
          backUpTableData.push({
            id: item.id,
            brand: item.brand,
            make: item.make,
            model: item.model,
            typeClass: item.typeClass,
            amountVAT: item.amountVAT
          });
          // 显示整数
          item.amountVAT = Math.round(parseFloat(item.amountVAT)).toString();
          if (item.children?.length > 0) {
            generate(item.children);
          } else {
            item.brand = '';
            item.typeClass = '';
            item.make = '';
            // eslint-disable-next-line
            // @ts-ignore
            delete item.children;
          }
        });
      };
      generate(tempData.value);
      changeCheckFlag();
      expandTypeClass();
      checkAllFlag();
    };

    // 获取brand
    getSpecBrand({ mcId: props.makeId }).then((res: any) => {
      brandArray.value.length = 0;
      res.forEach((item: any) => {
        brandArray.value.push({ id: item, isSelect: true });
      });
      getTreeTableData();
    });

    const selectBrand = (item: BrandType) => {
      item.isSelect = !item.isSelect;
      isBrand = true;
      getTreeTableData();
    };

    // 修改框添加千分符并只保留15位小数
    const inputAddMicrometer = (amountVAT: string) => {
      let str;
      str = amountVAT.replace(/[^\-\d.]/g, '');
      if (str) {
        str = str.replace(/,/g, '');
        const hasPot = str.indexOf('.');
        if (hasPot > -1) {
          let leftNum = str.substring(0, hasPot);
          let rightNum = str.substring(hasPot + 1, str.length);
          leftNum = leftNum.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
          if (rightNum.length > 15) {
            rightNum = str.substring(hasPot + 1, hasPot + 16);
          }
          str = leftNum + '.' + rightNum;
        } else {
          str = str.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
        }
        return str;
      } else {
        return '';
      }
    };

    // allocation input number
    const handelInputNumber = () => {
      inputNumber.value = inputAddMicrometer(inputNumber.value);
      inputNumber.value = inputNumber.value.replace(/,/g, '');
      const hasPot = inputNumber.value.indexOf('.');
      if (hasPot > -1) {
        let leftNum = inputNumber.value.substring(0, hasPot);
        let rightNum = inputNumber.value.substring(hasPot + 1, inputNumber.value.length);
        leftNum = leftNum.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
        if (rightNum.length > 2) {
          rightNum = inputNumber.value.substring(hasPot + 1, hasPot + 3);
        }
        inputNumber.value = leftNum + '.' + rightNum;
      } else {
        inputNumber.value = inputNumber.value.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
      }
    };

    // 保存按钮
    const handleSave = async () => {
      if (!name.value) {
        message.error('Name can not be empty');
        return;
      }
      if (checkFlag.value === 'Allocation' && inputNumber.value === '') {
        message.error('Allocation can not be empty');
        return;
      }
      const paramsData: ParamsDataType = {
        adjustmentNo: '',
        allocationAmount: inputNumber.value,
        allocationDataReqs: [],
        mcId: props.makeId,
        name: name.value,
        allocationType: checkFlag.value === 'Allocation' ? 1 : 0,
        topicCode: '',
        type: 2
      };
      const generate = (arr: TreeTableData[]) => {
        arr.forEach((item: TreeTableData) => {
          if (checkFlag.value === 'Allocation' && item.checked && !item.children) {
            backUpTableData.forEach((e) => {
              if (item.id === e.id) {
                paramsData.allocationDataReqs.push({
                  amount: item.amountVAT,
                  brand: e.brand,
                  llp: item.llp,
                  make: e.make,
                  model: e.model,
                  typeClass: e.typeClass,
                  volume: item.volume
                });
              }
            });
          } else if (checkFlag.value === 'Input' && !item.children) {
            backUpTableData.forEach((e) => {
              if (item.id === e.id) {
                paramsData.allocationDataReqs.push({
                  amount: e.amountVAT,
                  brand: e.brand,
                  llp: item.llp,
                  make: e.make,
                  model: e.model,
                  typeClass: e.typeClass,
                  volume: item.volume
                });
              }
            });
          }
          if (item.children?.length > 0) {
            generate(item.children);
          }
        });
      };
      generate(tempData.value);
      if (checkFlag.value === 'Allocation' && paramsData.allocationDataReqs.length < 1) {
        message.error('Please select model');
        return;
      }
      await postSaveData(paramsData);
      getTreeTableData();
      initData();
      emit('refresh-overview');
    };

    // 小数添加千分符
    const addWsllpMicrometer = (amountVAT: string) => {
      let str;
      str = amountVAT + '';
      if (str) {
        str = str.replace(/,/g, '');
        if (str.indexOf('.') > -1) {
          str = parseFloat(str).toFixed(0);
        }
        str = parseFloat(str) + '';
        str = str.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
        return str;
      } else {
        return '';
      }
    };

    // 千分符转化为number
    const removeMicrometer = (amountVAT: string) => {
      let number = 0;
      let str = amountVAT + '';
      str = str.replace(/,/g, '');
      if (str) {
        number = parseFloat(str);
      } else {
        number = 0;
      }
      return number.toString();
    };

    // 加法计算
    const accAdd = (num1: number, num2: number) => {
      let r1: string | number = '';
      let r2: string | number = '';
      let m: string | number = '';
      let c: string | number = '';
      try {
        r1 = num1.toString().split('.')[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split('.')[1].length;
      } catch (e) {
        r2 = 0;
      }
      c = Math.abs(r1 - r2);
      m = Math.pow(10, Math.max(r1, r2));
      if (c > 0) {
        const cm = Math.pow(10, c);
        if (r1 > r2) {
          num1 = Number(num1.toString().replace('.', ''));
          num2 = Number(num2.toString().replace('.', '')) * cm;
        } else {
          num1 = Number(num1.toString().replace('.', '')) * cm;
          num2 = Number(num2.toString().replace('.', ''));
        }
      } else {
        num1 = Number(num1.toString().replace('.', ''));
        num2 = Number(num2.toString().replace('.', ''));
      }
      return (num1 + num2) / m;
    };

    // 计算 input 的 Amount
    const calculateAmount = () => {
      const generate = (arr: TreeTableData[]) => {
        arr.forEach((item: TreeTableData) => {
          item.children.forEach((el) => {
            el.children.forEach((e) => {
              let esum = 0;
              for (let ei = 0; ei < e.children.length; ei++) {
                esum = accAdd(esum, parseFloat(removeMicrometer(e.children[ei].amountVAT)));
              }
              e.amountVAT = esum.toString();
            });
            let elsum = 0;
            for (let eli = 0; eli < el.children.length; eli++) {
              elsum = accAdd(elsum, parseFloat(removeMicrometer(el.children[eli].amountVAT)));
            }
            el.amountVAT = elsum.toString();
          });

          let itemsum = 0;
          for (let itemi = 0; itemi < item.children.length; itemi++) {
            itemsum = accAdd(itemsum, parseFloat(removeMicrometer(item.children[itemi].amountVAT)));
          }
          item.amountVAT = itemsum.toString();
        });
      };
      generate(tempData.value);
    };
    const amountVATFocus = (record: TreeTableData) => {
      backUpTableData.forEach((item) => {
        if (item.id === record.id) {
          console.log(item.amountVAT);
          record.amountVAT = item.amountVAT;
        }
      });
    };

    const amountVATChange = (record: TreeTableData) => {
      record.amountVAT = inputAddMicrometer(record.amountVAT);
      backUpTableData.forEach((item) => {
        if (item.id === record.id) {
          item.amountVAT = record.amountVAT;
          if (item.amountVAT.slice(1).indexOf('-') !== -1) {
            if (item.amountVAT[0] == '-') {
              item.amountVAT = '-' + item.amountVAT.replace(/-/g, '');
            } else {
              item.amountVAT = item.amountVAT.replace(/-/g, '');
            }
          }
        }
      });
      calculateAmount();
    };

    const amountVATBlur = (record: TreeTableData) => {
      record.amountVAT = inputAddMicrometer(Math.round(Number(removeMicrometer(record.amountVAT))).toString());
      if (record.amountVAT.slice(1).indexOf('-') !== -1) {
        if (record.amountVAT[0] == '-') {
          record.amountVAT = '-' + record.amountVAT.replace(/-/g, '');
        } else {
          record.amountVAT = record.amountVAT.replace(/-/g, '');
        }
      }
    };
    const bulrInputNumber = () => {
      if (inputNumber.value.slice(1).indexOf('-') !== -1) {
        inputNumber.value = 0;
      }
    };
    const leave = (e: any) => {
      e.target.blur();
    };

    const handleExport = () => {
      const params = {
        url: `/pcapi/api/v1/allocation/allocation/exportSpecialAdjOrRelease`,
        params: {
          mcId: props.makeId,
          type: 2
        }
      };
      blobDownload(params);
    };
    return {
      leave,
      checkFlag,
      dataTitle,
      columns,
      tableData,
      brandArray,
      selectBrand,
      brandColumns,
      data,
      changeCheckFlag,
      changeChecked,
      selectAll,
      allFlag,
      initData,
      totalTable,
      topic,
      topicArray,
      name,
      inputText,
      inputNumber,
      checked,
      expandedRowKeys,
      expandModel,
      onExpand,
      handleSave,
      handelInputNumber,
      amountVATFocus,
      amountVATChange,
      amountVATBlur,
      addWsllpMicrometer,
      tableHeight,
      bulrInputNumber,
      handleExport
    };
  }
});
