
import { defineComponent, ref, reactive } from "vue";
import {
  getDirectList,
  getTreeList,
  getCategory,
  getSubOverview,
  getTopic,
  getLockinfos
} from "@/API/closing";
import CbuUpload from "@/views/Closing/Tab/Components/BlueUpload.vue";
import VerTable from "@/views/Closing/Tab/Components/VerTable.vue";
import blobDownload from "@/utils/blobDownload";
interface MyItem {
  id: number;
  isActive: boolean;
  name: string;
}

const tableHeight = window.innerHeight - 400;
const tableHeightR = window.innerHeight - 443;
export default defineComponent({
  components: { CbuUpload, VerTable },
  emits: ["refresh-table"],
  props: {
    make: {
      type: String,
      default: ""
    },
    makeId: {
      type: Number,
      required: true
    },
    activeName: {
      type: String,
      default: ""
    }
  },
  setup(props, context) {
    //   统计表格信息
    const columnTotal = [
      {
        dataIndex: "Total",
        key: "Total",
        width: 120,
        slots: { title: "customTitle", customRender: "Total" }
      },
      {
        title: "",
        dataIndex: "countNum",
        key: "countNum"
      }
    ];
    const dataTotal = [
      {
        Total: "Until",
        countNum: 202
      },
      {
        Total: "SD%",
        countNum: "3%"
      }
    ];
    const showPrompt = ref("");
    // 温馨提示
    const getTopicInfo = () => {
      getTopic({ page: "BLUE_INVOICE" }).then(res => {
        showPrompt.value = res;
      });
    };
    getTopicInfo(); //获取提示信息
    // 上传
    const showDrawer = ref(false);
    const handleUpload = () => {
      showDrawer.value = true;
    };
    const closeDrawer = () => {
      showDrawer.value = false;
    };

    const dataTable: any = reactive([]);
    const dataTitle = ref("Total");

    //   分类表格信息(后端返回灵活信息)
    const columnCategory: any = reactive([]);
    const dataCategory = ref([]);

    // 左侧蓝票可折叠表格
    const columns = [
      {
        title: "Vehicle",
        dataIndex: "make",
        width: 66,
        align: "left",
        ellipsis: true
      },
      {
        title: "",
        dataIndex: "brand",
        width: 54,
        align: "left",
        ellipsis: true
      },
      {
        title: "",
        dataIndex: "typeClass",
        width: 60,
        align: "left",
        ellipsis: true
      },
      {
        title: "",
        dataIndex: "model",
        width: 100,
        align: "left",
        ellipsis: true
      },
      { title: "Volume", dataIndex: "volume", width: 60, align: "right" },
      { title: "Avg.SI%", dataIndex: "avgSi", width: 80, align: "right" },
      {
        title: "Amount w/o VAT",
        dataIndex: "amountVAT",
        width: 110,
        align: "right"
      }
    ];
    // 表格数据初始化
    const queryListData = ref<any[]>([]);

    // 响应式对象
    const blueCategory = reactive<MyItem[]>([]);

    // 处理已选中类别数组
    const handleCateGeryParam = () => {
      const paramsCate: any = [];
      if (blueCategory.length) {
        blueCategory.forEach((ele: MyItem) => {
          if (ele.isActive) {
            paramsCate.push(ele.name);
          }
          return paramsCate;
        });
        return paramsCate;
      } else {
        return [];
      }
    };
    // 复选框初始化
    const checked = ref<boolean>(false);
    // 表格行展开
    const expandedRowKeys = ref<any>([]);
    // 全部数据
    // 因expandedRowKeys设置之后展开行会有问题 所以重写点击行逻辑
    const onExpand = (expanded: boolean, record: any) => {
      if (expanded) {
        // 设置展开窗Key，代表展开操作
        expandedRowKeys.value.push(record.id);
      } else {
        // 代表折叠操作
        if (expandedRowKeys.value.length) {
          expandedRowKeys.value = expandedRowKeys.value.filter((v: any) => {
            return v !== record.id;
          });
        }
      }
    };

    // 获取数据所有id
    const getAllId = () => {
      const arr: any = [];
      const data = queryListData.value;
      data.length &&
        data.forEach((item: any) => {
          if (item.children && item.children.length) {
            arr.push(item.id);
            item.children.forEach((item: any) => {
              if (item.children && item.children.length) {
                arr.push(item.id);
                item.children.forEach((item: any) => {
                  arr.push(item.id);
                });
              }
            });
          }
        });
      return arr;
    };
    // 获取到Type Class层级所有数据id
    const getTypeClassId = () => {
      const arr: any = [];
      queryListData.value.length &&
        queryListData.value.forEach((item: any) => {
          arr.push(item.id);
          if (item.children && item.children.length) {
            arr.push(item.id);
            item.children.forEach((item: any) => {
              arr.push(item.id);
            });
          }
        });
      return arr;
    };

    // 展开type class层级
    const expandTypeClass = () => {
      const data = getTypeClassId();
      Object.assign(expandedRowKeys.value, data);
    };

    // 展开折叠
    const expandModel = () => {
      const data = getAllId();
      if (checked.value) {
        Object.assign(expandedRowKeys.value, data);
      } else {
        expandedRowKeys.value = [];
        expandTypeClass();
      }
    };
    // 右侧蓝票Adj或者BBAC表格
    const blueRightTable = [
      {
        title: "No.",
        key: "No.",
        width: 58,
        align: "center",
        customRender: function({ index }: any) {
          return index + 1;
        }
      },
      {
        title: "Commission No.",
        dataIndex: "commissionNo",
        key: "commissionNo",
        width: 118,
        align: "center",
        ellipsis: true
      },
      {
        title: "Material No.",
        dataIndex: "materialNo",
        key: "materialNo",
        width: 118,
        align: "center",
        ellipsis: true
      },
      {
        title: "Model Name",
        dataIndex: "modelName",
        key: "modelName",
        align: "left",
        ellipsis: true
      },
      {
        title: "Cash Discount",
        dataIndex: "cashDiscount",
        key: "cashDiscount",
        align: "right",
        width: 100
      },
      {
        title: "LLP",
        dataIndex: "llp",
        key: "llp",
        align: "right",
        width: 100
      }
    ];
    const blueRightTableData = ref([]);
    const blueRightLLPCount = ref(0);
    const blueRightCashCount = ref(0);

    // 获取price Adj或BBAC的信息 getTreeList
    const getDirectData = () => {
      getDirectList({ mcId: props.makeId == -1 ? 5 : props.makeId })
        .then(res => {
          blueRightTableData.value = res.detailVOList;
          blueRightCashCount.value = res.totalCashDiscount;
          blueRightLLPCount.value = res.totalLLP;
        })
        .catch(err => {
          console.log(err, "报错");
        });
    };
    // 递归数组
    const handleChildrenArr = (param: any) => {
      return param.forEach((ele: any) => {
        if (ele.children) {
          if (ele.children.length) {
            handleChildrenArr(ele.children);
          } else {
            delete ele.children;
          }
        }
        return param;
      });
    };
    // 获取左侧树形表格信息 getTreeList
    const getTreeData = async () => {
      const cateArr = Array.from(handleCateGeryParam());
      await getTreeList({
        mcId: props.makeId,
        categories: cateArr.length ? cateArr : [""]
      }).then(res => {
        // copaId.value = res.pId;
        queryListData.value = Array.from(res);
        // 处理掉左侧表格数组数据中叶子数据中的children空数组字段，以解决树形表格中的叶子表格行不展示多余的展开符号。
        handleChildrenArr(queryListData.value);
      });
      expandTypeClass();
    };
    // 获取分类
    const getCateGery = async () => {
      const res = await getCategory({ mcId: props.makeId });
      blueCategory.length = 0;
      Array.from(res).map((item, i: number) => {
        // const str = item;
        const ele = reactive<MyItem>({
          name: `${item}`,
          id: i,
          isActive: true
        });
        blueCategory.push(ele);
        return blueCategory;
      });
      handleCateGeryParam();
      getTreeData();
    };
    const getSubOver = () => {
      getSubOverview({ mcId: props.makeId })
        .then(res => {
          dataTable.length = 0;
          columnCategory.length = 0;
          dataCategory.value = [];
          dataTable.push(...res.totalTable);
          columnCategory.push(...res.tableHeaders);
          dataCategory.value = res.subOverviewItemDtos;
        })
        .catch(err => {
          console.log(err, "报错了");
        });
    };
    // 左侧表格接口
    // 左侧蓝票类别复选框
    const cateGerySel = (item: any) => {
      item.isActive = !item.isActive;
      //   每次改变状态都要触发一次计算
      handleCateGeryParam();
      getTreeData();
      getDirectData(); //临时调用
    };
    const handleUploadSuccess = () => {
      // 上传成功后刷新蓝票类别接口
      getCateGery();
    };
    // 导出按钮
    const exportFile = () => {
      blobDownload({
        url: "/pcapi/closing/blueInvoice/category/export",
        params: { mcId: props.makeId }
      });
    };
    const blueInvoiceLock = ref(false);
    // 计算事件allocation，计算蓝票表格数据
    const allocation = () => {
      context.emit("refresh-table");
      getSubOver(); //调用统计表格;
      getCateGery();
      getDirectData(); //临时调用
      getLockinfos({ mcId: props.makeId }).then(res => {
        blueInvoiceLock.value = res.blueInvoiceLock;
      });
    };
    allocation();
    return {
      showPrompt,
      getTopicInfo,
      checked,
      columnCategory,
      dataCategory,
      columnTotal,
      dataTotal,
      blueRightTable,
      blueRightTableData,
      columns,
      onExpand,
      expandedRowKeys,
      blueCategory,
      cateGerySel,
      expandModel,
      expandTypeClass,
      getTypeClassId,
      //   上传
      showDrawer,
      handleUpload,
      closeDrawer,
      handleUploadSuccess,
      exportFile,
      allocation,
      blueRightLLPCount,
      blueRightCashCount,
      getCateGery,
      handleCateGeryParam,
      dataTitle,
      dataTable,
      getSubOver,
      queryListData,
      tableHeight,
      tableHeightR,
      blueInvoiceLock
    };
  }
});
