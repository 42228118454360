
import { defineComponent, ref } from "vue";
import { getReport2, getBrandList } from "@/API/closing";
import { ReportData, ExtData, TableHeader } from "@/views/Closing/type";
import blobDownload from "@/utils/blobDownload";
import useExpand from "@/hooks/closing/useExpand";
interface BrandType {
  id: string;
  isSelect: boolean;
}
const tableHeight = window.innerHeight - 410;
export default defineComponent({
  props: {
    makeId: {
      type: Number,
      required: true
    },
    makeYear: {
      type: String,
      default: ""
    },
    tdValue: {
      type: String,
      required: true
    },
    planningId: {
      type: Number,
      required: true
    },
    kufriVersion: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const columns = ref<TableHeader[]>([]);
    const data = ref<ReportData[]>([]);
    const brandArray = ref<BrandType[]>([{ id: "", isSelect: false }]);
    const setColumnWidth = () => {
      const generate = (dataArray: TableHeader[]) => {
        dataArray.forEach((item: TableHeader) => {
          item.align = "center";
          if (item.title === 'Gap Amount') {
            item.width = '110px'
          } else if (item.children?.length > 0) {
            if (item.children.length === 1) {
              item.children[0].width = `${Math.max(
                item.children[0].title.length * 8,
                item.title.length * 8,
                80
              )}px`;
            }
            generate(item.children);
          } else {
            if (!item.width) {
              item.width = `${Math.max(item.title.length * 9, 80)}px`;
            }
          }
        });
      };
      generate(columns.value);
      columns.value[0].width = "450px";
    };


    // expand to model 复选框初始化
    const checked = ref<boolean>(false);

    // 获取报表数据
    let brand = "";
    const getReportData = () => {
      brand = "";
      brandArray.value.forEach((item: BrandType) => {
        if (item.isSelect) {
          brand = `${item.id},${brand}`;
        }
      });
      getReport2({
        mcId: props.makeId,
        year: props.makeYear,
        searchType: parseInt(props.tdValue),
        brand,
        planningId: props.planningId,
        kufriVersion: props.kufriVersion
      }).then(res => {
        checked.value = false
        columns.value = res.head;
        columns.value[0]['fixed']='left'
        data.value = res.content;
        setColumnWidth();
        let id = 0;
        const generate = (dataArray: ReportData[]) => {
          dataArray.forEach(item => {
            item.ext.forEach((ele: ExtData) => {
              item[ele.keyName] = ele.value;
              item.id = ++id;
            });
            if (item.children?.length > 0) {
              generate(item.children);
            } else {
              Reflect.deleteProperty(item, "children");
            }
          });
        };
        generate(data.value);
      });
    };

    const initData = async () => {
      const brandList = await getBrandList({ mcId: props.makeId });
      brandArray.value = [];
      brandList.forEach(item => {
        brandArray.value.push({ id: item, isSelect: true });
      });
      getReportData();
    };

    const selectBrand = (item: BrandType) => {
      item.isSelect = !item.isSelect;
      getReportData();
    };
    const handleExport = () => {
      const params = {
        url: `/pcapi/api/v1/reportData/exportOverviewByBrand`,
        params: {
          mcId: props.makeId,
          searchType: parseInt(props.tdValue),
          brand,
          planningId: props.planningId,
          kufriVersion: props.kufriVersion
        }
      };
      blobDownload(params);
    };
    const { onExpand, expandedRowKeys } = useExpand(data, checked);
    return {
      data,
      columns,
      tableHeight,
      brandArray,
      selectBrand,
      initData,
      handleExport,
      onExpand,
      checked,
      expandedRowKeys
    };
  }
});
